import React from 'react';

import {
    Box,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react';

const Dot = () => (
    <Flex flexDirection="column" h="100%" justifyContent="flex-start" alignItems="center">
        <Flex justifyContent="center" alignItems="center" cursor="pointer" position="relative">
            <Box
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    border: '2px solid #d7c60d',
                    background: 'transparent',
                }}
            />
                <Box 
                    style={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        background: '#d7c60d',
                        position: 'absolute'
                    }}
                />
               
        </Flex>
        <Box
           style={{
                width: '2px',
                background: '#d7c60d',
                height: '100%',
                top: '-12px'
            }}
        />
    </Flex>
)

export default function Step2() {
    return (
        <Box
         h="100%">
            <Flex w="100%" justifyContent="space-between" alignItems="flex-start" h="100%">
                <Box width="48%" />
                <Flex pt="2" justifyContent="center" h="100%" alignItems="flex-start">
                    <Dot />
                </Flex>
            <Box width="48%" pl="4"
                h="100%">
                <Heading color="brand.500" textAlign="left" variant="h2">
                    Step 2
                </Heading>
                <Text color="white" textAlign="left" fontSize={{base: '0.8rem', md: '1rem'}}>
                Conduct an initial assessment of stream channel and floodplain health, ultimately identifying and prioritizing project areas.
                </Text>
            </Box>
            </Flex>
        </Box>
    )
}