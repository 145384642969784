import {
  Icon
} from '@chakra-ui/react'

const WideningIcon = (props) => (
  
<Icon viewBox="0 0 251 162.20001" {...props}>
    <path class="cls-1" d="M140.20001,71.2h-29.5v-24.9c0-4.9-.2-5.1-5.2-5.1h-6.8c-.10001,0-.2-.1-.5-.2,9-13.6,18.10001-27.2,27.2-41,9.10001,13.8,18.2,27.4,27.39999,41.3h-9.10001c-2.8,0-3.60001,.8-3.60001,3.6v24.6c.10002,.39999,.10002,1,.10002,1.7Z"/>
    <path class="cls-1" d="M241.20001,126.59999c-2.29999-2.39999-4.29999-4.5-6.39999-6.60001-2-2-3-2-5,0-5.79999,5.8-11.60001,11.60001-17.39999,17.39999-.39999,.39999-.89999,.8-1.20001,1.2-6.89999-7-13.79999-13.8-21.10001-21.2,.39999-.2,1.10001-.5,1.5-.89999,5.79999-5.8,11.60001-11.60001,17.39999-17.39999,1.89999-1.89999,1.89999-3,0-4.89999-2.10001-2.10001-4.20001-4.10001-6.60001-6.39999,16.39999-3.3,32.39999-6.5,48.60001-9.8-3.19998,16.09999-6.39999,32.09999-9.79999,48.59999Z"/>
    <path class="cls-1" d="M39.7,138.5c-3.7-3.8-7.6-7.7-11.6-11.60001-2.3-2.3-4.6-4.60001-6.9-6.89999-2-2-3-1.89999-5,.10001s-4.1,4.10001-6.4,6.39999C6.5,110.2,3.3,94.2,0,77.90001c16.2,3.3,32.2,6.5,48.7,9.8-2.3,2.2-4.39999,4.2-6.4,6.2-2.2,2.2-2.2,3.10001,0,5.39999,5.7,5.7,11.4,11.39999,17.1,17,.5,.5,1,.8,1.39999,1.10001-7.09998,7.19998-13.99998,14.09999-21.09998,21.09999Z"/>
    <path class="cls-1" d="M73.5,130.8l10.8,10.8-20.5,20.5-10.8-10.8,20.5-20.5Z"/>
    <path class="cls-1" d="M166.60001,141.7c3.60001-3.60001,7.29999-7.3,10.89999-10.89999,6.79999,6.8,13.70001,13.7,20.60001,20.60001-3.60001,3.60001-7.39999,7.3-11,10.8-6.70001-6.70001-13.60001-13.60002-20.5-20.50002Z"/>
    <path class="cls-1" d="M140.10001,105h-29.2v-15.7h29.2v15.7Z"/>
    <path class="cls-1" d="M110.90001,127.7v-6.10001h29.2v6.10001h-29.2Z"/>
</Icon>
)

export default WideningIcon