import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Text,
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import WideningIcon from '../Icons/WideningIcon';

export const WideningMobile = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>      
        <Modal isOpen={isOpen} onClose={onClose} isCentered id="staff-modal" bg="red" size="sm">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Widening Channels</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text xw fontWeight="700" textAlign="center" fontSize="0.8rem">
            The stream banks along the Buxton River Park were identified as unstable and eroding due to loss of riparian forest vegetation, contributing to impaired aquatic habitat, loss of stream channel function and increased risk of flooding.
            </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
    
        <Box position="absolute" top="36%" left={{base: "12%", md: "29%", lg: "57%"}} w={{base: "20%", md: "15%"}} display={{ base: 'block', xl: 'none' }} cursor="pointer" onClick={onOpen}>
           <Flex alignItems="flex-start">
                <Flex flexDirection="column" justifyContent="center" alignItems="center"  >
                    <WideningIcon w="40%" h="24%" fill="white" />
                    <Heading as="h4" color="white" textAlign="center" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1">
                        Widening Channels
                    </Heading>
                </Flex>
                <Box mr="10px">
                <PulseDot />
            </Box>
           </Flex>
        </Box>
        <Modal />
        </>
    )
}


