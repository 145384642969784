import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Text,
    Link,
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';

import DegradedIcon from '../Icons/DegradedIcon'
import ArrowIcon from '../Icons/RightArrow';

export const DegradedHabitatMobile = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>      
        <Modal isOpen={isOpen} onClose={onClose} isCentered id="staff-modal" bg="red" size="sm">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Impaired Water Quality</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text xw fontWeight="700" textAlign="center" fontSize="0.8rem">
            Excessive sediment from eroding stream banks, over-widened channels and and increasing stream temperatures are factors that contributed to the Teton River’s listing as impaired for degraded habitat, sediment and temperature throughout its length. <Link href="https://mywaterway.epa.gov/waterbody-report/IDEQ/ID17040204SK020_04/2022" target=
                    "_blank" rel="noopener noreferrer" color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}}>Read the EPA Report <ArrowIcon pb="1" fill="brand.500"/></Link>
            </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
    
    <Box position="absolute" top="67%" left="46%" w="15%" display={{ base: 'block', xl: 'none' }} cursor="pointer" onClick={onOpen}>
           <Flex alignItems="flex-start">
               <Flex flexDirection="column" justifyContent="center" alignItems="center"  >
                    <DegradedIcon h="30%" w="30%" fill="white" />
                    <Heading as="h4" color="white" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1" textAlign="center">
                    Impaired Water Quality
                    </Heading>
                </Flex>
                <Box mr="-10px">
                <PulseDot />
            </Box>
           </Flex>
        </Box>
        <Modal />
        </>
    )
}


