import React from 'react';

import {
    Box,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react';

const Dot = () => (
    <Flex flexDirection="column" h="100%" justifyContent="flex-start" alignItems="center">
        <Flex justifyContent="center" alignItems="center" cursor="pointer" position="relative">
            <Box
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    border: '2px solid #d7c60d',
                    background: 'transparent',
                }}
            />
                <Box 
                    style={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        background: '#d7c60d',
                        position: 'absolute'
                    }}
                />
               
        </Flex>
        <Box 
            style={{
                width: '2px',
                background: '#d7c60d',
                height: '100%',
                position: 'relative',
                
            }}
        />
    </Flex>
)

export default function Step7() {
    return (
        <Box
            h="100%"
            //top="-200%"
        >
            <Flex w="100%" justifyContent="space-between" alignItems="flex-start" h="100%">
            <Box width="48%" pr="4"
            >
                <Heading color="brand.500" textAlign="right" variant="h2">
                    Step 7
                </Heading>
                <Text color="white" textAlign="right" fontSize={{base: '0.8rem', md: '1rem'}}>
                    Begin project fundraising in earnest. Consider funding sources that prioritize flood mitigation, water quality protection and fisheries and wildlife benefits.
                </Text>
            </Box>
            <Flex pt="2" justifyContent="center" h="100%" alignItems="flex-start"
            >
                    <Dot />
                </Flex>
                <Box width="48%" />
            </Flex>
           
        </Box>
    )
}