import React from 'react';

import {
    Box,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react';

const Dot = () => (
    <Flex flexDirection="column" h="100%" justifyContent="flex-start" alignItems="center">
        <Flex justifyContent="center" alignItems="center" cursor="pointer" position="relative">
            <Box
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    border: '2px solid #d7c60d',
                    background: 'transparent',
                }}
            />
                <Box 
                    style={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        background: '#d7c60d',
                        position: 'absolute'
                    }}
                />
               
        </Flex>
        <Box 
            style={{
                width: '2px',
                background: '#d7c60d',
                height: '100%',
                position: 'relative',
                left: '0',
            }}
        />
    </Flex>
)

export default function Step1() {
    return (
        <Box h="100%">
            <Flex w="100%" justifyContent="space-between" alignItems="flex-start" h="100%">
            <Box width="48%" pr="4">
                <Heading color="brand.500" textAlign="right" variant="h2">
                    Step 1
                </Heading>
                <Text color="white" textAlign="right" variant="steps" fontSize={{base: '0.8rem', md: '1rem'}}>
                    Identify and convene community stakeholders to discuss issues associated with degraded riparian areas and impaired stream function. Build trust and foster an understanding of what potential projects would address these issues. 
                </Text>
            </Box>
                <Box pt="2"
                justifyContent="center"
                h="100%" alignItems="flex-start"
                pb="2rem">
                    <Dot />
                </Box>
                <Box width="48%" h="100%"/>
            </Flex>
           
        </Box>
    )
}