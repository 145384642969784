import {
  Icon
} from '@chakra-ui/react'

const FishIcon = (props) => (
  
<Icon viewBox="0 0 237.02704 221.48775" {...props}>
<path class="cls-1" d="M231.60003,57.72884c2.10001,3.2,3.29999,6.5,4.29999,9.9,1.79999,6.2,1.20001,12.4-.10001,18.6-2.20001,10.9-7.39999,20.1-15.20001,27.9-6.29999,6.39999-13.20001,12.10001-21.10001,16.5-11.29999,6.3-22.7,12.39999-34.8,17.2-11.60001,4.5-23.39999,8-35.7,10-8.3,1.3-16.7,2.2-25,3.2-4.2,.5-8.3,.89999-12.60001,1.3-.10001,1.89999-.2,3.7-.39999,5.5-1.10001,10.7-5.10001,20.10001-12.89999,27.8-5.60001,5.5-12,9.5-19.9,10.5-2.3,.3-4.8,.3-7.1,.10001-12.6-.89999-25.2-1.8-37.8-2.7-4.1-.3-8.1-.60001-12.2-.89999-.4,0-.8-.3-1.1-.5,.3-.3,.6-.7,.9-.8,3.9-1.39999,7.8-2.7,11.8-4,5.8-1.89999,11.7-3.7,17.7-4.8,3.7-.60001,7.3-1.2,11-1.8,.7-.10001,1.6-.3,1.5-1.10001,0-.60001-.5-1.5-1.1-1.8-2.7-1.39999-5.5-2.8-8.2-4.10001-6.4-2.89999-13-5.5-19.8-7.2-1.8-.5-3.6-1.2-5.7-1.8,2.2-2.7,4.2-5.5,6.5-8,9.7-10.7,21.1-19.39999,33.4-27,14.2-8.7,29.50001-14.8,45.6-19,2.60001-.7,3.8-1.8,4.7-4.3,4.10001-11.5,8.5-22.9,12.7-34.4,.10001-.3,.3-.7,.39999-1,2.8,1.4,5.5,2.7,8.3,4.1,7.5,3.9,15.39999,4.9,23.60001,4.1,6.60001-.7,13.2-1.1,19.89999-1.7,7.60001-.6,15.3-1.3,22.89999-1.9,.89999-.1,1.79999-.1,2.89999-.2-.29999,.4-.39999,.6-.60001,.9-3.8,4.6-7.7,9.1-11.5,13.7-.7,.89999-1.39999,1.8-2,2.8-.3,.39999-.39999,1.2-.2,1.60001,.2,.39999,.8,.60001,1.3,.7,5.10001,.3,10.10001-.5,15-1.7,11.70001-2.89999,21.70001-8.9,30.29999-17.2,5.29999-5,8.29999-11.2,9.5-18.4,.20001-1.2,.5-2.4,.60001-3.6,.10001-1.1,.10001-2.3,.10001-3.4,.20004-1.00001,.10004-2.00001,.10004-3.10001ZM32.80003,162.12884c0,3.8,2.9,6.8,6.6,6.7,3.6,0,6.6-3.10001,6.6-6.8s-3-6.8-6.6-6.8c-3.6,.10001-6.6,3.10001-6.6,6.90001Z"/>
      <path class="cls-1" d="M72.40003,207.22883c14.3-9.3,23.7-21.7,24.5-39.39999,2.3-.3,4.39999-.5,6.5-.7,11.39999-1.2,22.89999-2.39999,34.2-4.60001,8.2-1.5,16-4.39999,23.89999-7.2,10.3-3.7,20.10001-8.60001,29.79999-13.5,7.70001-3.89999,15.20001-8.2,22.20001-13.3,.29999-.2,.60001-.39999,.79999-.7,1.70001-1.5,3.20001-3.2,5-4.60001,5.89999-4.7,10-10.8,14.29999-16.8,.29999-.39999,.60001-.8,1-1.39999-.5,5.10001-1.29999,9.8-2.60001,14.5-3.79999,14.7-10.20001,28.2-19.70001,40.09999-4.10001,5.2-9,9.8-14.39999,13.7-19.7,14.39999-41.5,24.3-65.39999,29.60001-7.3,1.60001-14.8,2.60001-22.3,3.10001-7.10001,.39999-14.10001,1.10001-21.2,1.3-5.60001,.2-11.3,0-17,0,.40004,.10001,.40004,0,.40004-.10001Z"/>
      <path class="cls-1" d="M152.00002,52.52884c1.10001-2.2,2.3-4.2,3.2-6.3,1.8-3.9,2-7.9,.39999-11.9-.2-.5-.39999-1-.8-1.4-7.7-10.1-15.5-20.3-23.2-30.4-.5-.7-.60001-1.3-.2-2,.39999-.7,1-.6,1.7-.3,16.89999,7.1,33.8,14.1,50.7,21.1,6.29999,2.6,12.60001,5.2,18.89999,7.8,4.39999,1.8,8.60001,4.1,12.20001,7.1,6.29999,5.2,10.39999,11.9,11.10001,20.3,.20001,2.8,.29999,5.5-.5,8.3-.10001,.3-.20001,.6-.29999,.8,0,0-.10001,.1-.20001,.1-.20001-1.1-.39999-2.2-.70001-3.3-2-7.5-7.10001-11.6-14.60001-13-6.10001-1.2-12.29999-1.5-18.5-1.4-5.60001,.1-11.2,.2-16.8,.8-6.39999,.7-12.8,1.9-19.10001,2.9-1.09996,.4-2.19997,.6-3.29997,.8Z"/>
      <path class="cls-1" d="M202.90002,191.32884c-3,2.2-5.79999,4.2-8.60001,6.2-9.2,7-17.60001,14.8-25.5,23.10001-.7,.7-1.39999,1.10001-2.39999,.7-2.39999-1-4.8-1.89999-7.10001-3.10001-4.89999-2.60001-9.8-5.2-14-8.89999-.8-.7-1.60001-1.5-2.5-2.39999,.5-.2,.8-.39999,1.2-.5,4.10001-1.3,8.2-2.5,12.3-3.89999,6-2,11.8-4.60001,17.39999-7.7,.60001-.3,1.3-.39999,2-.39999,2-.2,4.10001-.2,6.10001-.39999,5.2-.60001,10.29999-1.3,15.5-1.89999,1.80002-.10004,3.50003-.50003,5.60001-.80003Z"/>
</Icon>
)

export default FishIcon