import React from 'react';
import {
    Box,
    Flex,
    Text,
    Heading
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import FishIcon from '../Icons/FishIcon'
import { motion } from 'framer-motion';

const variants = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1.25,
            delay: 6,
        }
      }
    }

export const YellowstoneCutthroat = () => (
    <Box position="absolute" top="76%" left="77%" w="20%" h="auto"  display={{ base: 'none', xl: 'block' }}>
       <Flex alignItems="flex-start"
        // as={motion.div}
        // variants={variants}
        // whileInView="animate"
        // initial={{ 
        //     opacity: 0,
        // }}
        // viewport=
        // {{once: true}}
    >
                    <Box>
        <PulseDot />
        </Box>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" mt="-16%">

            <FishIcon h="10%" w="17%" fill="brand.500" />
                <Heading as="h4" fontSize="clamp(1rem, 2vw, 1.25rem)" my="1" textAlign="center" color="brand.500">
                Yellowstone Cutthroat<br/>Need Our Help
                </Heading>
                <Box w="95%" >
                    <Text color="brand.500" fontWeight="700" textAlign="center" fontSize="0.8rem">
                    The upper Teton River is a restoration priority because it is one of the remaining strongholds for Yellowstone Cutthroat Trout (YCT) populations, which have been lost from much of their historic range. Protecting, conserving and improving YCT habitats is critical to the persistence of this species of concern.
                    </Text>
                </Box>
            </Flex>
       </Flex>
    </Box>
)