import React from 'react';
import {
    Box,
    Flex,
    Text,
    Heading
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import WideningIcon from '../Icons/WideningIcon'
import { motion } from 'framer-motion';

const variants = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1.25,
            delay: 4.5,
        }
      }
    }

export const Widening = () => (
    <Box position="absolute" top="39%" left="31%" w="20%" h="auto" display={{ base: 'none', xl: 'block' }}>
       <Flex>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" mt="0">
                <WideningIcon h="25%" w="27%" fill="white" />
                <Heading as="h4" color="white" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1">
                    Widening<br />Channels
                </Heading>
                <Box w="70%" >
                    <Text color="white" fontWeight="700" textAlign="center" fontSize="0.8rem">
                        Unstable stream banks collapse into the river, create over-widened channels and increased sediment loads in the river.
                    </Text>
                </Box>
            </Flex>
            <Box ml="-45%">
            <PulseDot />
        </Box>
       </Flex>
    </Box>
)