import React from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Heading,
  Text
} from '@chakra-ui/react';
import './styles.css';
import theme from './theme'
import HeroSlide from './components/HeroSlide/HeroSlide';
import TetonRiverVideo from './components/TetonRiverVideo/TetonRiverVideo';
import TheSite from './components/TheSite/TheSite';
import ProblemSection from './components/ProblemSection/ProblemSection';
import RiparianForests from './components/RiparianForests/RiparianForests';
import TheSolution from './components/TheSolution/TheSolution';
import ProjectPartners from './components/ProjectPartners/ProjectPartners';
import HowToEngage from './components/HowToEngage/HowToEngage';
import Footer from './components/Footer/Footer'
import { FramerScroll } from './components/TheResults/FramerScroll';
import GsapComponent from './components/TheResults/GsapComponent';
import GsapComponent2 from './components/TheResults/GsapComponent2';
//import { motion, useScroll, useTransform } from 'framer-motion';
  


function App() {
  // const { scrollYProgress } = useScroll();
  // const y = useTransform(scrollYProgress, [0, 1], [0, -1000]);
  return (
    <ChakraProvider theme={theme}>
      <Box as="main" 
      scrollSnapType={{base: "none", md: "y mandatory"}} 
      //causing issues with framer motion: 
      //overflowY="scroll"
      height={{base: "100%", md: "100vh"}}>
        <HeroSlide />
          <TetonRiverVideo />
          <TheSite />
          <ProblemSection />
          <RiparianForests />
          <TheSolution />
          <GsapComponent />
          {/* <FramerScroll /> */}
          
          <ProjectPartners />
          <HowToEngage />
          <Footer />
        </Box>
      </ChakraProvider>
  );
}

export default App;
