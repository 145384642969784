import React from 'react';

import {
    Box,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react';

const Dot = () => (
    <Flex justifyContent="center" alignItems="center" cursor="pointer">
        <Box
            style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                border: '2px solid #d7c60d',
                background: 'transparent',
            }}
        />
            <Box 
                style={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    background: '#d7c60d',
                    position: 'absolute'
                }}
            />
    </Flex >
)

export default function Step9() {
    return (
        <Box
            h="100%"
            //top="-325%"
        >
            <Flex w="100%" justifyContent="space-between" alignItems="flex-start">
            <Box width="48%" pr="4"
            >
                <Heading color="brand.500" textAlign="right" variant="h2">
                    Step 9
                </Heading>
                <Text color="white" textAlign="right" fontSize={{base: '0.8rem', md: '1rem'}}>
                    Post project completion, monitor conditions at project site, while continuing to steward and maintain the riparian forest. Work with agencies to monitor project effectiveness and determine if project objectives were met.
                </Text>
            </Box>
                <Flex pt="2" justifyContent="center"
               >
                    <Dot />
                </Flex>
                <Box width="48%" />
            </Flex>
           
        </Box>
    )
}