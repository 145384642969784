import React, { useRef, useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  Box,
  Heading,
  Flex,
  Text,
  Link,
} from '@chakra-ui/react';
import ArrowIcon from '../Icons/RightArrow';
import { motion } from 'framer-motion';
import BatesSiteVideo from '../../videos/Bates-Site-Video.mp4'

const textVariant = {
    animate: {
      opacity: 1,
      transition: {
          duration: 1.25,
          delay: 1.5,
      }
    }
  }

 const photoVariant = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1.25,
            delay: .5,
        }
    }
    }

export default function TheSite() {
    const [inViewRef, inView] = useInView({
        threshold: 1,
    })
    const videoRef = useRef(null);
    
    const setRefs = useCallback(
        // Refs from useRef needs to have the node assigned to `current`
        (node) => {
            videoRef.current = node;
            // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
            inViewRef(node)

        if (node) {
            node.addEventListener('click', function () {
                if (this.paused) {
                    this.play();
                } else {
                    this.pause();
                }
            })
        }
        }, [inViewRef]
    )

    useEffect(() => {
        if (!videoRef || !videoRef.current) {
            return 
        }

        if (inView) {
            videoRef.current.play()
        } else {
            videoRef.current.pause()
        }
    }, [inView])

    return (
        <Box as="section" bg="white" scrollSnapAlign={{base: "none", md: "start"}} py="12" >
            <Flex w="100%" flexWrap="wrap" justifyContent={{base: "space-between", lg: "space-around"}} alignItems="center" h="100%" >
                <Box w={{base: "100%", xl: "50%"}} px={{base: "2%", md: "5%"}} py="12"
                    as={motion.div}
                    initial={{ 
                        opacity: 0
                    }}
                    variants={textVariant}
                    whileInView="animate"
                    viewport={{once: true}}
                >
                    <Flex mb="4">
                        <Box bg="brand.500" w="0.375rem" />
                        <Box pl="2">
                            <Heading as="h2" variant="h2" color="gray.600"  mb="0">
                                The Site
                            </Heading>
                        </Box>
                    </Flex>
                    <Box pl="4">
                        <Text>
                        This riparian forest restoration project took place on the upper Teton River, near the center of Teton Valley, at the Buxton River Park. 
                        </Text>
                        <Text>
                            Buxton River Park is named after the Buxton family who homesteaded this property in the early 1900’s. This property was previously used for livestock grazing before the 80-acre parcel was acquired by Teton County, Idaho and conservation partners in 2017 for public access, safety and for habitat conservation (42-acre easement portion).
                        </Text>
                        <Text mb="12">Approaching restoration work with the watershed scale in mind can ensure that improvements in channel function, riparian forest habitat and flood mitigation are created throughout the whole river system. Restoration work is taking place in multiple locations in the Teton River watershed, including mountain tributaries and numerous river reaches in Teton Valley.
                        </Text>
                        <Text textTransform="uppercase" mb="0">
                            Learn More about the Buxton River Park Access Project
                        </Text>
                        <Box mb="4">
                            <Link color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}} href="https://www.tetonwater.org/featured-work/bates-public-access/" target="_blank" rel="noopener noreferrer">
                                Buxton River Park Access Project <ArrowIcon pb="1" fill="brand.500"/>
                            </Link>
                        </Box>
                        <Text textTransform="uppercase" mb="0">
                            Learn More about other restoration projects in the Teton watershed
                        </Text>
                        <Box>
                            <Link color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}} href="https://www.tetoncreekcorridor.org" target="_blank" rel="noopener noreferrer">
                                Teton Creek Corridor Project <ArrowIcon pb="1" fill="brand.500"/>
                            </Link>
                        </Box>
                        <Box>
                            <Link color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}} href="https://www.savetetonriver.org/initiatives/canyon-creek" target="_blank" rel="noopener noreferrer">
                                Canyon Creek <ArrowIcon pb="1" fill="brand.500" />
                            </Link>
                        </Box>
                    </Box>
                </Box>
                <Box w={{base: "100%", xl: "50%"}} maxH="100vh" overflow="hidden" mb={{base: "12", lg: "0"}}
                 as={motion.div}
                 initial={{ 
                    //display: 'none',
                    opacity: 0
                 }}
                 variants={photoVariant}
                 whileInView="animate"
                 viewport={{once: true}}
                 >
                    <video controls="true" ref={setRefs} autoplay="true" muted="true" className='homeVideo'>
                        <source  src={BatesSiteVideo} type="video/mp4" />
                    </video>
                    {/* <img src={BuxtonRiverParkImg} alt="Buxton River Park" style={{maxHeight: '100vh'}} />     */}
                </Box>
            </Flex>    
        </Box>
    )
}