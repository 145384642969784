import React from 'react';
import {
    Box,
    Center,
    Container,
    Flex,
    Heading,
    Text,
    Link
} from '@chakra-ui/react';
import FTRLogo from '../../images/ftr-logo.svg';
import FooterBackgroundImage from '../../images/footer-background.jpg';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const StyledLink = styled(Link)`
    color: white;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    display: block;
    &:hover {
        text-decoration: none;
        color: #d7c60d;
    }
`;

export default function Footer() {
    return (
        <Box
            scrollSnapAlign={{base: "none", md: "start"}}
            as="footer"
            bgImage={`url(${FooterBackgroundImage})`}
            bgSize="cover"
            bgPosition="center"
            bgRepeat="no-repeat"
            color="white"
            position="relative"
        >
            <Box position="relative" top="0" left="0" width="100%" height="100%" bgColor="#41351dc8" py="24">
                <Container maxW="container.xl">
                    <Flex w="100%" flexWrap="wrap" justifyContent="space-between" alignItems="center" py="8">
                        <Box w={{base: "100%", md:"33.33%"}} px="12" mb="12"
                        as={motion.div}
                        initial={{ 
                            opacity: 0
                        }}
                            whileInView={{
                                opacity: 1,
                                transition: {
                                    delay: .5,
                                    duration: 1.25,
                                    type: 'easeInOut',
                                    stiffness: 100
                                }
                            }}
                        >
                                <Box mb="4">
                                    <Center>
                                        <img src={FTRLogo} alt="Friends of the Teton River" style={{ width: '8rem'}} />
                                    </Center>
                                </Box>
                                <Heading as="h3" color="white" textAlign="center" fontSize="1.25rem" mb="4"> 
                                    About Friends of<br />the Teton River
                                </Heading>
                                <Text color="white" fontWeight="500" textAlign="center">
                                The mission of Friends of the Teton River is to restore and conserve the Teton River watershed, ensuring a lasting legacy of clean water, healthy streams and a thriving wild fishery. We implement programs and projects founded on sound science, community education and cooperation with landowners, citizens and agency partners.
                                </Text>
                                <Box textAlign="center">
                                    <Link href="mailto:info@tetonwater.org" color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}}>
                                    Contact Us
                                    </Link>
                                </Box >
                        </Box>
                        <Box w={{base: "100%", md:"33.33%"}} px="12" mb="12" textAlign={{base: 'center', md: 'center'}}
                        as={motion.div}
                        initial={{ 
                            opacity: 0
                        }}
                            whileInView={{
                                opacity: 1,
                                transition: {
                                    delay: 1,
                                    duration: 1.25,
                                    type: 'easeInOut',
                                    stiffness: 100
                                }
                            }}
                        >
                            <Heading as="h4" color="white" fontSize="1.25rem" mb="4">
                            Friends of the Teton River Resources
                            </Heading>
                            <Box>
                                <StyledLink href="https://drive.google.com/file/d/1M_o6jxZqG1qQtRgUmfbGxUeUbcl78NnF/view?usp=share_link" target="_blank" rel='noopener noreferrer'>
                                    Geomorphic Assessment
                                </StyledLink>
                                <StyledLink href="https://drive.google.com/file/d/1YgE4RfR2GKuR6Lv2Kx03Qi6fMDfKO5kT/view?usp=share_link" target="_blank" rel='noopener noreferrer'>
                                    Riparian Forest Management
                                </StyledLink>
                                <StyledLink href="https://drive.google.com/file/d/1VbSZCtimgfXeIE7zWmzhRkCeRsENNOmT/view?usp=share_link" target="_blank" rel='noopener noreferrer'>
                                    Management Treatment Priorities
                                </StyledLink>
                            </Box>
                        </Box>
                        <Box w={{base: "100%", md:"33.33%"}} px="12" mb="12" textAlign={{base: 'center', md: 'center'}}
                        as={motion.div}
                        initial={{ 
                            opacity: 0
                        }}
                            whileInView={{
                                opacity: 1,
                                transition: {
                                    delay: 1.5,
                                    duration: 1.25,
                                    type: 'easeInOut',
                                    stiffness: 100
                                }
                            }}
                        >
                            <Heading as="h4" color="white" fontSize="1.25rem" mb="4">
                                Other Regional Restoration Projects
                            </Heading>
                            <Box>
                                <StyledLink href="https://www.henrysfork.org/post/south-fork-rainey-creek-restoration-phase-one-complete" target="_blank" rel='noopener noreferrer'>
                                    HFF Rainey Restoration
                                </StyledLink>
                                <StyledLink href="https://wgfd.wyo.gov/Regional-Offices/Jackson-Region/Jackson-Region-News/Flat-Creek-Habitat-Improvement-Project-to-Begin" target="_blank" rel='noopener noreferrer'>
                                    Flat Creek Improvement
                                </StyledLink>
                                <StyledLink href="https://idrange.org/range-stories/eastern-idaho/lemhi-ranchers-enhance-fish-habitat-via-harmonious-25-year-partnership/" target="_blank" rel='noopener noreferrer'>
                                    Lemhi Basin Project
                                </StyledLink>
                            </Box>
                        </Box>
                    </Flex>
                </Container>
                <Box>
                        <Text textAlign="center" mb="0" fontSize="0.9rem">
                        This institution is an equal opportunity provider. This project is funded in part by the Idaho Department of Lands in cooperation with the USDA Forest Service.
                        </Text>
                    </Box>
            </Box>
        </Box>
    )
}