import React from 'react';
import {
  Box,
  Flex,
  Container,
  Heading,
  Center,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Portal,
  useDisclosure,
  Link
} from '@chakra-ui/react';
import BureauOfReclamationLogo from '../../images/logos/bureau-of-reclamation-logo.png';
import DepartmentOfLandsLogo from '../../images/logos/department-of-lands-logo.png';
import FTRLogo from '../../images/logos/ftr-logo.png';
import IdahoFishGameLogo from '../../images/logos/idaho-fish-game-logo.png';
import NaturalResourcesLogo from '../../images/logos/natural-resources-conservation-service-logo.png';
import TetonCountyLogo from '../../images/logos/teton-county-logo.png';
import TetonRegionalLandTrustLogo from '../../images/logos/teton-regional-land-trust-logo.png';

import { motion } from 'framer-motion';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 2,
      staggerChildren: 0.5,
    },
  },
};

const item = {
  hidden: { opacity: 0, scale: 0.5 },
  show: { opacity: 1, scale: 1 },
  hover: { cursor: 'pointer' },
};

export default function ProjectPartners() {
  const { isOpen: isFtrOpen, onOpen: onFtrOpen, onClose: onFtrClose } = useDisclosure();
  const { isOpen: isIdahoFishOpen, onOpen: onIdahoFishOpen, onClose: onIdahoFishClose } = useDisclosure();
  const { isOpen: isTetonCountyOpen, onOpen: onTetonCountyOpen, onClose: onTetonCountyClose } = useDisclosure();
  const { isOpen: isLandTrustOpen, onOpen: onLandTrustOpen, onClose: onLandTrustClose } = useDisclosure();
  const { isOpen: isReclamationOpen, onOpen: onReclamationOpen, onClose: onReclamationClose } = useDisclosure();
  const { isOpen: isLandsOpen, onOpen: onLandsOpen, onClose: onLandsClose } = useDisclosure();
  const { isOpen: isConservationOpen, onOpen: onConservationOpen, onClose: onConservationClose } = useDisclosure();

  return (
    <Box
      as="section"
      scrollSnapAlign={{ base: 'none', md: 'start' }}
      height="100vh"
      position="relative"
      bg="white"
    >
      <Flex flexDirection="column" justifyContent="center" h="100%">
        <Container maxW="container.xl" textAlign="center" my="24">
          <Center>
            <Box h="0.5rem" bg="brand.500" w="4rem" mb="4" />
          </Center>
          <Heading
            as="h2"
            variant="h2"
            color="gray.600"
            mb="16"
          >
            Project Partners
          </Heading>
          <Flex
            w="100%"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="center"
            as={motion.div}
            variants={container}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="project-partners"
          >
            
              <Popover className="popover" isOpen={isFtrOpen} onClose={onFtrClose}>
                <PopoverTrigger>
                  <Box
                    p="12"
                    w={{ base: '50%', md: '33.33%', lg: '25%' }}
                    as={motion.div}
                    variants={item}
                    whileHover="hover"
                    id="ftr"
                    onMouseEnter={onFtrOpen}
                    onMouseLeave={onFtrClose}
                  >
                    <Link href="https://www.tetonwater.org" target="_blank" rel="noopener noreferrer">
                    <img
                      src={FTRLogo}
                      alt="Friends of the Teton River logo"
                      style={{ marginLeft: 'auto', marginRight: 'auto', width: '70%' }}
                    />
                    </Link>
                    </Box>
                </PopoverTrigger>
                <Portal>
                <PopoverContent _focus={{boxShadow: 'none'}}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text textAlign="center" fontWeight="700">Friends of the Teton River</Text>
                    <Text textAlign="center">Facilitator for stakeholder conversations, restoration project manager, fundraising for state and federal grant dollars and private donations for matching dollars.</Text >
                  </PopoverBody>
                </PopoverContent>
                </Portal>
              </Popover>

              <Popover className="popover" isOpen={isIdahoFishOpen} onClose={onIdahoFishClose}>
                <PopoverTrigger>
            <Box
              p="4"
              w={{ base: '50%', md: '33.33%', lg: '25%' }}
              as={motion.div}
              variants={item}
              whileHover="hover"
              id="fish-game"
              onMouseEnter={onIdahoFishOpen}
              onMouseLeave={onIdahoFishClose}
            >
              <Link href="https://idfg.idaho.gov" target="_blank" rel="noopener noreferrer">
              <img
                src={IdahoFishGameLogo}
                alt="Idaho Fish and Game logo"
                style={{ marginLeft: 'auto', marginRight: 'auto', width: '60%' }}
              />
              </Link>
            </Box>
            </PopoverTrigger>
                <Portal>
                <PopoverContent _focus={{boxShadow: 'none'}}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text textAlign="center" fontWeight="700">
                      Idaho Fish &amp; Game
                    </Text>
                    <Text textAlign="center">
                      Supports fisheries research and population monitoring, manages river access point.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
                </Portal>
              </Popover>

              <Popover className="popover" isOpen={isTetonCountyOpen} onClose={onTetonCountyClose}>
                <PopoverTrigger>
                  <Box
                    p="4"
                    w={{ base: '50%', md: '33.33%', lg: '25%' }}
                    as={motion.div}
                    variants={item}
                    whileHover="hover"
                    id="teton-county"
                    onMouseEnter={onTetonCountyOpen}
                    onMouseLeave={onTetonCountyClose}
                  >
                    <Link href="https://www.tetoncountyidaho.gov/index.php" target="_blank" rel="noopener noreferrer">
                    <img
                      src={TetonCountyLogo}
                      alt="Teton County logo"
                      style={{ marginLeft: 'auto', marginRight: 'auto', width: '60%' }}
                    />
                    </Link>
                  </Box>
                  </PopoverTrigger>
                <Portal>
                <PopoverContent _focus={{boxShadow: 'none'}}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text textAlign="center" fontWeight="700">
                      Teton County, ID
                    </Text>
                    <Text textAlign="center">
                      Purchased land parcel where restoration took place.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
                </Portal>
              </Popover>

              <Popover className="popover" isOpen={isLandTrustOpen} onClose={onLandTrustClose}>
                <PopoverTrigger>  
                  <Box
                    p="4"
                    w={{ base: '50%', md: '33.33%', lg: '25%' }}
                    as={motion.div}
                    variants={item}
                    whileHover="hover"
                    id="land-trust"
                    onMouseEnter={onLandTrustOpen}
                    onMouseLeave={onLandTrustClose}
                  >
                    <Link href="https://tetonlandtrust.org" target="_blank" rel="noopener noreferrer">
                    <img
                      src={TetonRegionalLandTrustLogo}
                      alt="Teton Regional Land Trust logo"
                      style={{ marginLeft: 'auto', marginRight: 'auto', width: '70%' }}
                    />
                    </Link>
                  </Box>
                  </PopoverTrigger>
                <Portal>
                <PopoverContent _focus={{boxShadow: 'none'}}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text textAlign="center" fontWeight="700">
                      Teton Regional Land Trust
                    </Text>
                    <Text textAlign="center">
                      Manages conservation easement at Buxton River Park.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
                </Portal>
              </Popover>

              {/* <Popover className="popover" isOpen={isReclamationOpen} onClose={onReclamationClose}>
                <PopoverTrigger>  
            <Box
              p="4"
              w={{ base: '50%', md: '33.33%', lg: '25%' }}
              as={motion.div}
              variants={item}
              whileHover="hover"
              id="reclamation"
              onMouseEnter={onReclamationOpen}
              onMouseLeave={onReclamationClose}
            >
              <Link href="https://www.usbr.gov" target="_blank" rel="noopener noreferrer">
              <img
                src={BureauOfReclamationLogo}
                alt="Bureau of Reclamation logo"
                style={{ marginLeft: 'auto', marginRight: 'auto', width: '70%', width: '60%' }}
              />
              </Link>
            </Box>
            </PopoverTrigger>
                <Portal>
                <PopoverContent _focus={{boxShadow: 'none'}}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text textAlign="center" fontWeight="700">
                      Bureau of Reclamation
                    </Text>
                    <Text textAlign="center">
                      Funded restoration work through the Watersmart Program.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
                </Portal>
              </Popover> */}

              {/* <Popover className="popover" isOpen={isLandsOpen} onClose={onLandsClose}>
                <PopoverTrigger>  
                <Box
                  p="4"
                  w={{ base: '50%', md: '33.33%', lg: '25%' }}
                  as={motion.div}
                  variants={item}
                  whileHover="hover"
                  id="lands"
                  onMouseEnter={onLandsOpen}
                  onMouseLeave={onLandsClose}
                >
                  <Link href="https://www.idl.idaho.gov" target="_blank" rel="noopener noreferrer">
                  <img
                    src={DepartmentOfLandsLogo}
                    alt="Department of Lands logo"
                    style={{ marginLeft: 'auto', marginRight: 'auto', width: '70%' }}
                  />
                  </Link>
                </Box>
                </PopoverTrigger>
                <Portal>
                <PopoverContent _focus={{boxShadow: 'none'}}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text textAlign="center" fontWeight="700">
                      Idaho Dept. of Lands
                    </Text>
                    <Text textAlign="center">
                      Funding for riparian forest restoration on the upper Teton River
                    </Text>
                  </PopoverBody>
                </PopoverContent>
                </Portal>
              </Popover> */}

              {/* <Popover className="popover" isOpen={isConservationOpen} onClose={onConservationClose}>
                <PopoverTrigger>
                  <Box
                    p="4"
                    w={{ base: '50%', md: '33.33%', lg: '25%' }}
                    as={motion.div}
                    variants={item}
                    whileHover="hover"
                    id="conservation"
                    onMouseEnter={onConservationOpen}
                    onMouseLeave={onConservationClose}
                  >
                    <Link href="https://www.nrcs.usda.gov" target="_blank" rel="noopener noreferrer">
                    <img
                      src={NaturalResourcesLogo}
                      alt="Natural Resources Conservation Service logo"
                      style={{ marginLeft: 'auto', marginRight: 'auto', width: '70%' }}
                    />
                    </Link>
                  </Box>
                  </PopoverTrigger>
                <Portal>
                <PopoverContent _focus={{boxShadow: 'none'}}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text textAlign="center" fontWeight="700">
                      National Resources Conservation Service
                    </Text>
                    <Text textAlign="center">
                      Mapping and monitoring soil health in Teton Valley, partner for conservation work with agriculture producers.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
                </Portal>
              </Popover> */}

          </Flex>
        </Container>
      </Flex>
    </Box>
  );
}
