import React from 'react';
import { Box, Container, Heading, Flex, Text } from '@chakra-ui/react';
import MooseImg from '../../images/moose-river-2.jpg';
import MoosePortraitImg from '../../images/moose-river-portrait.jpg';
import { motion } from 'framer-motion';

const styles = {
  backgroundSize: 'cover',
};

export default function HeroSlide() {
  return (
    <Box as="section">
      <Box
        backgroundImage={{
          base: `url(${MoosePortraitImg})`,
          md: `url(${MooseImg})`,
        }}
        w="100%"
        h="100vh"
        backgroundPosition="top center"
        style={styles}
        scrollSnapAlign="start"
        position="relative"
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1,
            transition: {
                duration: 1.25,
            }
        }}
      >
        <Box
          w="25vw"
          bg="#138f9a8e"
          backgroundBlendMode="multiply"
          position="absolute"
          top="0"
          left="10%"
          zIndex="0"
          as={motion.div}
          initial={{ 
            height: 0,
            opacity: 0
        }}
            animate={{
                height: '100vh',
                opacity: 1,
                transition: {
                    delay: 1,
                    duration: 1.25,
                    type: 'easeInOut',
                    stiffness: 100
                }
            }}
        />
        <Box h="100%" zIndex="1" position="relative">
          <Flex
            flexDirection="column"
            h="100%"
            justifyContent={{
              base: 'center',
              md: 'space-between',
              lg: 'center',
            }}
          >
            <Container
              maxW={{ base: 'container.xl', md: 'container.lg' }}
              mt={{ base: '24', md: '12', lg: 'auto' }}
            >
              <Box ml={{ base: 8, xl: 0 }}
                as={motion.div}
                initial={{ 
                    opacity: 0
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        duration: 1.25,
                        delay: 2.5,
                    }
                }}
                >
                <Heading
                  color="white"
                  fontSize={{ base: '12vw', md: '4vw', lg: '6vw' }}
                  textAlign="left"
                  as="h1"
                  textTransform="uppercase"
                  lineHeight="1"
                >
                  Restoring
                  <br />
                  Unstable
                  <br />
                  Stream Banks
                </Heading>
                <Heading
                  textTransform="uppercase"
                  color="white"
                  fontSize={{ base: '1rem', md: '1.5rem' }}
                  textAlign={{ base: 'left', lg: 'center' }}
                  pr="8"
                >
                  At The Buxton River Park
                </Heading>
              </Box>
            </Container>
            <Box mt={{ base: '24', md: '12', lg: '32' }}>
              <Flex
                w="100%"
                justifyContent={{
                  base: 'flex-end',
                  md: 'flex-start',
                  lg: 'flex-end',
                }}
                pl={{ base: 0, md: 4, lg: 0 }}
                as={motion.div}
                initial={{ 
                    opacity: 0
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        duration: 1.25,
                        delay: 3.5,
                    }
                }}
              >
                <Box bg="brand.500" w="0.375rem" />
                <Box
                  pl="2"
                  pr="8"
                  w={{ base: '80%', md: '50%', lg: '33.33%', xl: '25%' }}
                >
                  <Heading
                    as="h3"
                    textTransform="none"
                    fontFamily="DINOT"
                    color="white"
                    fontSize={{ base: '1.25rem', lg: '1.5rem' }}
                    textAlign="left"
                  >
                    Improving habitat, stream channel stability, and floodplain
                    function on the Teton River
                  </Heading>
                </Box>
              </Flex>
            </Box>
            <Box color="white" textAlign="center" mt="auto" mb="2" 
            as={motion.div}
            initial={{ 
                opacity: 0
            }}
            animate={{
                opacity: 1,
                transition: {
                    duration: 1.5,
                    delay: 4.5,
                }
            }}>
              <Text
                fontWeight="700"
                textTransform="uppercase"
                mb={{ base: 2, md: 1, lg: 2 }}
              >
                A Project From
              </Text>
              <Heading as="h4" textTransform="uppercase" fontSize="1.5rem">
                Friends of the Teton River
              </Heading>
            </Box>
            <Box mb="2" color="white" textAlign="right" mr="4" fontSize="0.75rem">
              Photo: Timothy Cushman
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
