import {
  Icon
} from '@chakra-ui/react'

const ErosionIcon = (props) => (
  
<Icon viewBox="0 0 166.34011 141.6" {...props}>
      <path class="cls-1" d="M8.40892,34.19998c8.8,2.2,17.3,4.6,25.89999,6.4,4,.9,6.7,2.7,8.8,6.4,6.60001,11.4,13.3,22.6,20.3,33.8,1.3,2.10001,3.5,4,5.7,5.2,8.2,4.39999,16.7,8.2,24.89999,12.7,2.8,1.60001,5.5,4.2,7.10001,7,4.3,7.7,8.2,15.60001,11.89999,23.60001,1.39999,3,3.10001,4.10001,6.39999,4,13.70001-.2,27.29999-.2,41,.10001,2,0,5.29999,1.60001,5.70001,3.10001,1.10001,3.89999-1.89999,5.10001-5.29999,5.10001H7.90893C.50894,141.6,.00894,141.1,.00894,133.6V5.7C-.09107,2.7,.60894,0,4.10894,0c3.39999,0,4.2,2.5,4.2,5.6-.10001,8.3,0,16.7,0,25-.10002,1.09998-.00002,2.09998,.09998,3.59998Z"/>
      <path class="cls-1" d="M131.00891,95.79998c-5.5,1.7-8.8-1.60001-10.7-7.2-.3-.89999-.89999-1.8-1.3-2.7-2.89999-5.8-.60001-12.10001,5.5-14.60001,.89999-.39999,2.20001-.10001,3.29999,.2,1.29999,.39999,2.39999,1.3,3.60001,1.7,8.29999,2.39999,11.29999,7.7,10.20001,16.3-.79999,6-.39999,6.3-6.70001,6.3h-3.89999Z"/>
      <path class="cls-1" d="M93.80893,67.69997c-5.7,1.8-9.39999-2.39999-13.10001-6.5-2.2-2.39999-2.10001-4.39999,.8-6.10001,.3-.2,.60001-.3,.89999-.39999,16.5-7.9,12.10001-9.3,20.39999,6.60001,2.39999,4.60001,1.2,6.3-4.10001,6.39999-1.39998,.10001-2.69998,0-4.89998,0Z"/>
      <path class="cls-1" d="M70.70892,34.19998c-6.2-2.1-12.60001-3.9-18.7-6.5-3.10001-1.3-2.8-4,.10001-5.7,3.7-2.1,7.60001-4.2,11.60001-5.7,1.3-.5,4,.5,4.7,1.7,2.3,3.6,4.2,7.5,5.89999,11.5,1.29999,3.2-.3,4.7-3.60001,4.7Z"/>
</Icon>
)

export default ErosionIcon