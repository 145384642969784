import React from 'react';
import { motion } from 'framer-motion';
import {
    Box,
    Flex
} from '@chakra-ui/react';

export const PulseDot = () => (
    <Flex justifyContent="center" alignItems="center" cursor="pointer">
        <motion.div
            animate={{
                scale: [1, 1.5, 1],
            }}
            transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: 'easeInOut',
            }}
            style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                border: '2px solid #d7c60d',
                background: 'transparent',
                // position: 'absolute',
                // top: '50%',
                // left: '50%',
                // transform: 'translate(-50%, -50%)',
            }}
        />
            <motion.div 
                animate={{
                    scale: [1, 1.3, 1],
                }}
                transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: 'easeInOut',
                }}
                style={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    background: '#d7c60d',
                    position: 'absolute'
                }}
            />
    </Flex >
)