import React from 'react';

import { Box, Flex, Container, Heading, Center } from '@chakra-ui/react';
import DiggingStreambank from '../../images/digging-streambank.jpg';
import ConstructionVideo from '../../videos/Construction.mp4';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';
import Step8 from './Steps/Step8';
import Step9 from './Steps/Step9';

import { motion } from 'framer-motion';

export default function TheSolution() {

  return (
    <Box
      as="section"
      scrollSnapAlign={{ base: 'none', md: 'start' }}
    >

        <Box
          backgroundImage={{base: `url(${DiggingStreambank})`, lg: 'none'}}
          backgroundSize="cover"
          backgroundPosition="top center"
          w="100%"
        >
        <Box
            position="relative"
            top="0"
            left="0"
            bg="rgba(0,0,0,0.75)"
            w="100%"
            h="100%"
          >
        <Box display={{ base: 'none', lg: 'block' }} position="absolute" zIndex="-1" h="100%">
          <video
            autoplay="true"
            loop="true"
            muted="true"
            className="homeVideo"
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100vw',
              height: '100%',
              background: 'transparent',
              objectFit: 'cover',
              backgroundPosition: 'top center',
            }}
          >
            <source src={ConstructionVideo} type="video/mp4" />
          </video>
        </Box>

            <Flex
            position="relative"
            flexDirection="column"
            justifyContent="center"
            h="100%"
            width="100%"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          >
            <Box w="100%">
              <Box mt={{ base: 12, md: 12 }} ml={{ base: 0, md: 8 }} px={{base: 2, md: 0}}>
                <Flex
                  w="100%"
                  flexWrap="wrap"
                  justifyContent={{ base: 'center', lg: 'flex-start' }}
                >
                  <Box w={{ base: '100%', md: '40%' }} mb="12"
                  as={motion.div}
                  viewport={{once: true}}
                  initial={{ 
                      opacity: 0
                  }}
                      whileInView={{
                          opacity: 1,
                          transition: {
                              delay: .5,
                              duration: 1.25,
                              type: 'easeInOut',
                              stiffness: 100
                          }
                      }}
                  >
                    <Flex flexDirection={{base: 'column', lg: 'row'}}>
                      <Center display={{ base: 'flex', lg: 'none' }}>
                        <Box h="0.5rem" bg="brand.500" w="4rem" mb="4" />
                      </Center>
                      <Box
                        bg="brand.500"
                        w={{ base: '20%', lg: '0.375rem' }}
                        h={{ base: '0.375rem', lg: 'auto' }}
                        display={{ base: 'none', lg: 'block' }}
                      />
                      <Box pl={{base: 0, lg: 2}}>
                        <Heading
                          as="h2"
                          variant="h2"
                          color="white"
                          mb="0"
                          textAlign={{ base: 'center', lg: 'left' }}
                        >
                          The Solution
                        </Heading>
                      </Box>
                    </Flex>
                  </Box>
                  <Flex
                    w={{ base: '100%', md: '60%' }}
                    flexDirection="column"
                    justifyContent="space-between"
                    pr={{base: 0, lg: 8}}
                    mb="24"
                    as={motion.div}
                    viewport={{once: true}}
                    initial={{ 
                        opacity: 0
                    }}
                        whileInView={{
                            opacity: 1,
                            transition: {
                                delay: 1.5,
                                duration: 1.25,
                                type: 'easeInOut',
                                stiffness: 100
                            }
                        }}
                  >
                      <Box><Step1 /></Box>
                      <Box mt={{base: "-15%", lg: "-5%"}}><Step2 /></Box>
                      <Box mt={{base: "-15%", lg: "-1.5%"}}><Step3 /></Box>
                      <Box mt={{base: "-15%", lg: "-2%"}}><Step4 /></Box>
                      <Box mt={{base: "-15%", lg: "-2%"}}><Step5 /></Box>
                      <Box mt={{base: "-15%", lg: "-2%"}}><Step6 /></Box>
                      <Box mt={{base: "-15%", lg: "-2%"}}><Step7 /></Box>
                      <Box mt={{base: "-15%", lg: "-2%"}}><Step8 /></Box>
                      <Box mt={{base: "-7%", lg: "-2%"}}><Step9 /></Box>
                    
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Flex>
            </Box>
            
          </Box>
    </Box>
  );
}
