import { Flex, Box, Heading, HStack } from '@chakra-ui/react';
import { forwardRef } from 'react';

const ResultsItem = forwardRef(function ResultsItem(props, ref) {
  return (
    <Flex
      className="panel"
      ref={ref}
      position="absolute"
      w="100%"
      flexWrap="nowrap"
      flexDirection={{ base: 'column', lg: 'row' }}
      justifyContent={{ base: 'space-between', lg: 'space-betwen' }}
      alignItems={{ base: 'center', lg: 'center' }}
      h="100%"
      id={props.id}
    >
      <Flex
        w={{ base: '100%', lg: '50%' }}
        h="100%"
        flexDir="column"
        justifyContent="center"
        px="12"
        position="relative"
      >
        <Box className="text" id={props.textID} fontSize={{base: "0.9rem", sm: "1rem", md: "1.25rem"}} pr={{ base: 2, lg: 12 }}>
          {props.content}
        </Box>
      </Flex>
      <Flex
        w={{ base: '100%', lg: '50%' }}
        h={{ base: 'auto', lg: '100%' }}
        flexDir="column"
        justifyContent="center"
        className="photo"
        id={props.photoID}
      >
        <Box
          bg="white"
          id="resultImg1"
          bottom={{ base: 0, lg: 'unset' }}
        >
          <img src={props.image} alt="Buxton River Park" />
        </Box>
      </Flex>
    </Flex>
  );
});

export default ResultsItem;
