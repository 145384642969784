import { extendTheme } from '@chakra-ui/react'

const theme = {
   styles: {
    global: {
        body: {
            color: 'brand.900',
            fontFamily: 'DINOT, sans-serif',
        },
        p: {
            marginBottom: '1rem',
        }
    },
   },
    colors: {
        brand: {
            500: '#d7c60d',
            900: '#1a202c',
        },
    },
    components: {
        Heading: {
            baseStyle: {
                fontFamily: 'Bravest, sans-serif',
                fontSize: [ '7vw', '3vw', '3.25vw' ]
            },
            variants: {
                'h1' : {
                    fontSize: '4xl',
                },
                'h2' : {
                    fontSize: [ '7vw', '3vw', '3.25vw' ]
                }
            },
        Text: {
            baseStyle: {
            fontFamily: 'DINOT, sans-serif',
            marginBottom: '1rem',
            },
            variants: {
                'steps': {
                    fontSize: '0.8rem'
                }
            }
        }
        }
    }
}

export default extendTheme(theme);