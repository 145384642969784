import {
  Icon
} from '@chakra-ui/react'

const DegradedIcon = (props) => (
  
<Icon viewBox="0 0 157.46531 204.65" {...props}>
  <path class="cls-1" d="M.0653,102.5V51.1c0-6.9-.2-13.7,.10001-20.6C.5653,19.1,5.96531,10.4,15.66531,4.4,20.66531,1.3,26.26532,0,32.16531,0h62.40001c4,0,7.3,1.7,10.39999,3.9,5.10001,3.5,9.5,8,13.89999,12.3,7.79999,7.7,15.60001,15.4,23.39999,23.2,4,4,7.89999,8,11.10001,12.6,2.60001,3.8,4.10001,7.9,4.10001,12.6-.10001,36.2,0,72.5,0,108.7,0,16.10001-12.89999,30.09999-28.89999,31.09999-5.89999,.39999-11.70001,.20001-17.60001,.20001H32.26532c-10,0-18.3-3.70001-24.8-11.2C2.76532,187.90001,.06532,181.50002,.06532,174.20001c-.10001-23.70001-.00002-47.70001-.00002-71.70001Zm15.7,0v69.89999c0,1.8,.2,3.60001,.60001,5.2,1.8,6.8,8,11.39999,15.3,11.39999H126.06532c8.5,0,15.60001-7.10001,15.60001-15.60001V70.89998c-.10001-3.8-1.70001-6-5.29999-6.9-2.29999-.6-4.79999-.8-7.20001-.9-8.79999-.1-17.5,0-26.3-.1-5.10001,0-8.39999-3.4-8.39999-8.5v-14.5c0-5.3,0-10.7-.3-16-.10001-1.8-.7-3.7-1.5-5.3-.89999-1.8-2.5-2.9-4.7-2.9H31.36534c-8.7,0-15.7,7.1-15.7,15.9v27.2c.09996,14.60002,.09996,29.10002,.09996,43.60002Z"/>
  <path class="cls-1" d="M78.2653,157.60001H38.7653c-4.3,0-7.7-3.10001-8-7.3-.3-4.10001,2.60001-7.7,6.7-8.39999,.39999-.10001,.7-.10001,1.10001-.10001H117.56532c3.60001,0,6.39999,1.5,7.89999,4.8,2.20001,5.10001-1.60001,10.89999-7.20001,10.89999-13.39999,.10001-26.7,.10001-40,.10001h0Z"/>
  <path class="cls-1" d="M78.36531,94.60001H38.7653c-4.5,0-8-3.39999-8.10001-7.89999-.10001-4.3,3.5-7.89999,8-7.89999H117.56529c4.5,0,7.89999,2.8,8.29999,6.89999,.60001,4.8-3,8.8-7.79999,8.8h-14.89999c-8.19998,.09999-16.49998,.09999-24.79999,.09999h0Z"/>
  <path class="cls-1" d="M70.36531,126.10001h-31.60001c-4.5,0-8.10001-3.5-8.10001-8,0-4.3,3.60001-7.8,8-7.8h62.89999c3,0,5.60001,.89999,7.3,3.5,1.8,2.60001,1.89999,5.39999,.39999,8.2-1.39999,2.7-3.8,4-6.8,4.10001h-32.09998Z"/>
</Icon>
)

export default DegradedIcon