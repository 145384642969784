import React from 'react';
import {
    Box,
    Flex,
    Text,
    Heading
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import FishIcon from '../Icons/FishIcon'
import { motion } from 'framer-motion';

const variants = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1.25,
            delay: 5.5,
        }
      }
    }


export const DecreasedFisheries = () => (
    <Box position="absolute" top="36%" left="60%" w="20%" h="auto" display={{ base: 'none', xl: 'block' }}>
       <Flex alignItems="flex-start"
    //    as={motion.div}
    //         variants={variants}
    //         whileInView="animate"
    //         initial={{ 
    //             opacity: 0,
    //         }}
    //         viewport=
    //     {{once: true}}
        >
       <Box mr="-11%">
        <PulseDot />
        </Box>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" mt="-29%">
                <FishIcon h="10%" w="17%" fill="white" />
                <Heading as="h4" color="white" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1" textAlign="center">
                    Loss of<br />Fish Habitat
                </Heading>
                <Box w="75%" >
                    <Text color="white" fontWeight="700" textAlign="center" fontSize="0.8rem">
                    In this section of the upper Teton River, loss of riparian forest contributes to increased sedimentation that can smother spawning gravels and loss of vegetation that creates shade and quality in-stream habitat for fish.
                    </Text>
                </Box>
            </Flex>
       </Flex>
    </Box>
)