import {
  Icon
} from '@chakra-ui/react'

const TreeIcon = (props) => (
  
<Icon viewBox="0 0 221.93864 253" {...props}>
<path class="cls-1" d="M91.06951,0h7.89999c.5,.1,1.10001,.3,1.7,.4,9.2,1.4,17,5.6,23,12.8,1.5,1.8,2.89999,3.8,4.3,5.7,.7-.3,1.3-.6,1.89999-.9,19.40001-9.8,41.7-2.1,50.90001,17.8,.79999,1.6,1.70001,2.3,3.39999,2.6,22.60001,4.1,35.89999,26.1,29.10001,48-.29999,.9-.10001,2.3,.5,3,15.10001,19.8,8.60001,46.7-14,57.10001-1.60001,.8-2.20001,1.7-2.5,3.5-3.29999,20.60001-23.89999,34.60001-44.29999,29.89999-4.60001-1.10001-8.89999-3.10001-12.60001-4.5-4,3.7-8.10001,7.5-12.39999,11.2-2.7,2.39999-4.10001,5.2-4.60001,8.8-1.89999,12.10001-2.8,24,.2,36,1.8,7.10001,3,14.29999,4.5,21.60001h-44.90001c8.3-25,9.8-50.5,6.3-76.39999-4.5,.10001-8.39999-2-12.2-4.2-2.60001-1.60001-5.2-3.10001-7.7-4.89999-1.3-1-2.39999-1-3.8-.3-24.60001,10.89999-52-6.89999-52.1-33.89999,0-1.60001-.5-2.60001-1.6-3.60001-17.3-15.89999-15.8-43.4,3.2-57.2,1.4-1,1.9-2.1,1.9-3.9-.5-18,12.9-34.3,30.6-37,3.60001-.6,7.3-.5,11.10001-.8,.2-.6,.3-1.3,.5-2,2.8-11.3,9.3-19.8,19.7-24.9,3.7-1.80001,7.89999-2.60002,12-3.90001Zm42,168.39999c-5.2-5.8-8-12.39999-9.3-19.5-4.10001,.60001-6.10001,2.8-6.39999,6.60001-.10001,1.5-.8,2.89999-1,4.3-.60001,4.3-1.5,8.60001-1.60001,12.89999-.2,4,2.10001,5.3,5.8,4,4.8-1.8,8.59999-4.89999,12.5-8.3Zm-44.2-19.09999c3.5,6.8,6.39999,13.10001,11.10001,18.3,2.7,3,5,2.7,6.5-1.10001,1.7-4.2,2.7-8.7,4.2-13.60001-7.90001,.90001-14.70001-.29999-21.8-3.59999Zm-12.3,11.09999c4.39999,2.60001,8.60001,5.5,14.39999,5.3-2.39999-5.10001-4.8-10-7.10001-14.89999-2.39999,3.2-4.79999,6.2-7.29999,9.59999Z"/>
</Icon>
)

export default TreeIcon