import React, {useLayoutEffect, useRef } from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Box, 
    Heading,
    Flex,
    Center
} from '@chakra-ui/react';
import ProblemMap from '../../images/the-problem-background.jpg';
import ProblemMapMobile from '../../images/the-problem-bg-mobile.jpg';
import { Erosion } from './Erosion';
import { Trampling } from './Trampling';
import { TramplingMobile } from './TramplingMobile';
import { Widening } from './Widening';
import { DecreasedFisheries } from './DecreasedFisheries';
import { YellowstoneCutthroat } from './YellowstoneCutthroat';
import { DegradedHabitat } from './DegradedHabitat';
import { motion } from 'framer-motion';
import { ErosionMobile } from './ErosionMobile';
import { WideningMobile } from './WideningMobile';
import { DecreasedFisheriesMobile } from './DecreasedFisheriesMobile';
import { YellowstoneCutthroatMobile } from './YellowstoneCutthroatMobile';
import { DegradedHabitatMobile } from './DegradedHabitatMobile';

gsap.registerPlugin(ScrollTrigger);

const box = {
  heading: {
    opacity: 1,
    transition: {
        duration: 1.25,
        delay: 1,
    }
  }
}

export default function TetonRiverVideo() {
  const scrollComponent = useRef(null);
  const pinner = useRef(null);
  const trampling = useRef(null);
  const erosion = useRef(null);
  const widening = useRef(null);
  const decreasedFisheries = useRef(null);
  const yellowstoneCutthroat = useRef(null);
  const degradedHabitat = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let contents = gsap.utils.toArray(".problem");
      gsap.set(contents, { autoAlpha: [0,1] });

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinner.current,
          scrub: true,
          pin: true,
          start: "top top",
          end: "+=3000, bottom",
          markers: false,
          // stagger: {
          //   each: 1,
          // }
        }
      });
      contents.forEach((content) => {
        tl.to(content, {
          //keyframes: { y: [0, 20, 40, 60, 80, 100] },
          duration: .5,
          autoAlpha: 1,
          stagger: {
            each: 1,
          }
        });
      });
    }, scrollComponent);
    return () => ctx.revert();
  });

  return (
    <Box
      w="100%"
      as="section"
      scrollSnapAlign={{base: "none", md: "start"}}
      //display={{base: 'none', md: 'block'}}
      ref={scrollComponent}
    >
        <Box position="relative"
          ref={pinner}
          className="pinBox"
          h="100vh"
        >
            <Box display={{base: 'none', xl: 'block'}}><img src={ProblemMap} alt="map of the Teton River" style={{objectFit: 'cover', objectPosition: 'bottom', height: '100vh', width: '100%'}} /></Box>
            <Box display={{base: 'block', xl: 'none'}}><img src={ProblemMapMobile} alt="map of the Teton River" style={{objectFit: 'cover', objectPosition: 'bottom', height: '100vh', width: '100%'}} /></Box>
            <Flex
              w="100%"
              h="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
                position="absolute"
                top="0"
                left="0"
            >
              <Box mt={{base: 4, lg: 12}} ml={{base: 0, lg: 12}} h="100vh" w="100%">
                <Flex as={motion.div}
                  flexDirection={{base: 'column', lg: 'row'}}
                    variants={box}
                    whileInView="heading"
                    initial={{ 
                      opacity: 0,
                   }}
                   viewport={{once: true}}>
                      <Center display={{ base: 'flex', md: 'none'}}>
                        <Box h="0.5rem" bg="brand.500" w="4rem" mb="4" />
                      </Center>
                    <Box bg="brand.500" w={{ base: "20%", md: "0.375rem" }} h={{base: "0.375rem", md: "auto"}}  display={{ base: 'none', md: 'block'}}/>
                  <Box pl="2"
                  >
                    <Heading as="h2" variant="h2" color="white" mb="0" textAlign={{base: 'center', md: 'left'}}>
                      What was
                      <br />
                      the problem?
                    </Heading>
                  </Box>
                </Flex>
              </Box>
            </Flex>
            <Box ref={trampling} className="problem">
                <Trampling />
                <TramplingMobile />
            </Box>
            <Box ref={erosion} className="problem">
              <Erosion />
              <ErosionMobile />
            </Box>
            <Box ref={widening} className="problem">
              <Widening />
              <WideningMobile />
            </Box>
            <Box ref={decreasedFisheries} className="problem">
              <DecreasedFisheries />
              <DecreasedFisheriesMobile />
            </Box>
            <Box ref={degradedHabitat} className="problem">
              <DegradedHabitat />
              <DegradedHabitatMobile />
            </Box>
            <Box ref={yellowstoneCutthroat} className="problem">
              <YellowstoneCutthroat />
              <YellowstoneCutthroatMobile />
            </Box>
        </Box>
    </Box>
  );
}
