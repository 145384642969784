import React from 'react';
import {
    Box,
    Flex,
    Text,
    Heading
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import ErosionIcon from '../Icons/ErosionIcon'
import { motion } from 'framer-motion';

const variants = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1.25,
            delay: 3.5,
        }
      }
    }

export const Erosion = () => (
    <Box position="absolute" top="22%" left="12%" w="20%" h="auto" display={{ base: 'none', xl: 'block' }}>
        <Flex justifyContent="flex-start" alignItems="flex-start"
        // as={motion.div}
        // variants={variants}
        // whileInView="animate"
        // initial={{ 
        //     opacity: 0,
        // }}
        // viewport={{once: true}}
        >
            <Flex flexDirection="column" justifyContent="center" alignItems="center" mr="-58%">
                <ErosionIcon w="10%" h="10%" fill="white" />
                <Heading as="h4" color="white" fontSize="clamp(1rem, 2vw, 1.5rem)" my="2">
                    Erosion
                </Heading>
                <Box w="56%">
                    <Text color="white" fontWeight="700" textAlign="center" fontSize="0.8rem">
                        The stream banks along the Buxton River Park were identified as unstable and eroding due to loss of riparian forest vegetation, contributing to impaired aquatic habitat, loss of stream channel function and increased risk of flooding.
                    </Text>
                </Box>
            </Flex>
            <Box>
                <PulseDot />
            </Box>
        </Flex>
    </Box>
)