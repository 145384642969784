import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Text,
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import TramplingIcon from '../Icons/TramplingIcon'

export const TramplingMobile = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>      
        <Modal isOpen={isOpen} onClose={onClose} isCentered id="staff-modal" bg="red" size="sm">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Land Use Impacts</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text xw fontWeight="700" textAlign="center" fontSize="0.8rem">
            Past land use practices such as unmanaged grazing, removal of vegetation, and excessive sedimentation.
            </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
    
        <Box position="absolute" top="24%" left="64%" h="auto" w="15%" display={{ base: 'block', xl: 'none' }} cursor="pointer" onClick={onOpen}>
           <Flex alignItems="flex-start">
               <Box mr="10px"> <PulseDot /></Box>
                <Flex flexDirection="column" justifyContent="center" alignItems="center" mt="-29%" >
                    <TramplingIcon h="25%" w="45%" fill="white" />
                    <Heading as="h4" color="white" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1" textAlign="center">
                        Land Use Impacts
                    </Heading>
                </Flex>
           </Flex>
        </Box>
        <Modal />
        </>
    )
}