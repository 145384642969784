import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef, useState } from "react";

import {
  Box,
  Flex,
  HStack,
  Heading
} from '@chakra-ui/react'
import TheResultsImg from '../../images/Results-Photo-1.jpg'
import TheResultsImg2 from '../../images/Results-Photo-2.jpg'
import TheResultsImg3 from '../../images/Results-Photo-3.jpg'
import TheResultsImg4 from '../../images/Results-Photo-4.jpg'
import TheResultsImg5 from '../../images/Results-Photo-5.jpg'
import TheResultsImg6 from '../../images/Results-Photo-6.jpg'
import ResultsItem from "./ResultsItem";

gsap.registerPlugin(ScrollTrigger);

const Dot = () => {
  return (
    <Box w="10px" h="10px" borderRadius="50%" my="2" className="dot" />
  )
}

export default function GsapComponent() {
  const component = useRef(null);
  const pinSlider = useRef(null);
  const innerRefs = useRef([]);
  const innerRef = useRef(null);
  const [ctx] = useState(gsap.context(() => {}, component.current));

  

  useLayoutEffect(() => {
    ctx.add(() => {

      const panels = gsap.utils.toArray('.panel');
      const indicators = gsap.utils.toArray('.dot');
      const texts = gsap.utils.toArray('.text');
      const images = gsap.utils.toArray('.img');
      const height = 100 * panels.length;
      
      let tl = gsap.timeline({
        duration: panels.length,
        scrollTrigger: {
          trigger: ".container",
          scrub: true,
            // snap: {
            //   snapTo: 1 / panels.length,
            //   duration: .5,
            //   delay: .5,
            //   ease: "slow.inOut",
            // },
          start: "top top",
          end: "+="+height+"%",
          markers: false,
        }
      })

      gsap.timeline({
        scrollTrigger: {
          trigger: ".container .wrapper",
          scrub: true,
          start: "top top",
          end: "+="+height+"%",
          pin: ".container",
          pinSpacing: true,
      }
    })

    panels.forEach(function(item, index) {
      tl.to(indicators[index], {
        backgroundColor: '#d7c60d',
      }, index)
      tl.from(item.querySelector('.photo'), {
        autoAlpha: 0,
      }, index)
      tl.from(item.querySelector('.text'), {
        autoAlpha: 0,
        }, index)

      if (index !== panels.length - 1) {
        tl.to(indicators[index], {
          backgroundColor: 'lightgray',
        }, index + 0.75)
        tl.to(item.querySelector('.text'), {
          autoAlpha: 0,
          translateY: -100,
        }, index + 0.75)
        tl.to(item.querySelector('.photo'), {
          autoAlpha: 0,
        }, index + 0.75)
      }
    });


      


    //   panels.forEach((panel, i) => {
    //     tl.addLabel(`panel-${i}`)
    //     .to(panel, {
    //       autoAlpha: 1,
    //     })
    //   }); 
    });
    return () => ctx.revert();
  }, [ctx]);  



  return (
    <Box ref={component} as="section" h="100vh" position="relative" className="container">
      <Box className="wrapper">
        <Box position="absolute" top={{base: "5%", sm: "15%", xl: "30%"}} zIndex="10" left="50">
         <HStack>
            <Box bg="brand.500" w="0.375rem" h="50px" />
                    <Box pl="2">
                        <Heading as="h2" variant="h2" color="gray.600" mb="0" display={{ base: 'none', lg: 'inline'}}>
                        The Results
                        </Heading>
                        <Heading as="h2" variant="h2" color="gray.600" mb="0" display={{ base: 'inline', lg: 'none'}} fontSize="2rem">
                        The Results
                        </Heading>
                    </Box>
         </HStack>
        </Box>
        <Box position="absolute" left="0" top={{base: "30%", md: "50%"}} translateY="50%" zIndex="1" ml="2">
          <Box w="10px" h="10px" borderRadius="50%" my="2" className="dot" bgColor="d7c60de"/>
          <Box w="10px" h="10px" borderRadius="50%" my="2" className="dot" />
          <Box w="10px" h="10px" borderRadius="50%" my="2" className="dot" />
          <Box w="10px" h="10px" borderRadius="50%" my="2" className="dot" />
        </Box>
          <ResultsItem
            id="panel-1" 
            textID="text-1"
            photoID="photo-1"
            ref={innerRefs}
            content="1,720 linear feet of stabilized and re-vegetated stream banks at the Buxton River Park will decrease erosion and improve water quality in the Teton River."
            image={TheResultsImg}
          />
          <ResultsItem 
            ref={innerRefs}
            content='Increased riparian vegetation will create stream shading, promote cooler stream temperatures, stabilize banks, provide desirable fish and wildlife habitat, slow stream velocities and promote the natural capture of sediment in stream banks with increased "vegetative roughness."'
            image={TheResultsImg2}
          />
          <ResultsItem
            ref={innerRefs}
            content="Restored stream banks at Buxton River Park will improve sediment conveyance, reducing sediment loads at this site by an estimated 448 tons/year, which will promote cleaner spawning gravels and improve native trout habitat."
            image={TheResultsImg3}
          />
          <ResultsItem
            ref={innerRefs}
            content="Managed grazing adjacent to stream bank restoration is possible with off-stream watering sites, hardened river access/crossings and removable fencing. These strategies allow for livestock to be a part of working lands restoration that improves and protects water quality."
            image={TheResultsImg4}
          />
      </Box>
    </Box>
  )
}