import React, { useRef } from 'react';
import { Box, Container, Heading, Flex, Text } from '@chakra-ui/react';
import RiverPlaceholderImg from '../../images/teton-river-video-placeholder.jpg';
import { motion } from 'framer-motion';
import TetonRiver from '../../videos/Waterflowing.mp4';

const styles = {
  backgroundSize: 'cover',
};

const variants = {
  animate: {
    opacity: 1,
    transition: {
        duration: 1.25,
        delay: 1,
    }
}
}

export default function TetonRiverVideo() {

  const videoRef = useRef(null); 

  return (
    <Box
        as="section"
        backgroundImage={{base: `url(${RiverPlaceholderImg})`, lg: 'none'}}
        w="100%"
        backgroundSize="cover"
        backgroundPosition="top center"
        style={styles}
        scrollSnapAlign={{ base: 'none', md: 'start' }}
        h={{ base: '100vh', md: '100vh' }}
        position="relative"
    >
       <Box display={{base: 'none', lg: 'block'}} h="100vh">
          <video ref={videoRef} autoplay="true" loop="true" muted="true" className='homeVideo' style={{marginLeft: 'auto', marginRight: 'auto', width: '100vw', height: '100vh', background: 'transparent', objectFit: 'cover', backgroundPosition: 'top center'}}>
            <source src={TetonRiver} type="video/mp4" />
          </video>
       </Box >
      <Box h="100%" zIndex="12" position="absolute" py="12" top="0" left="0" w="100%" bg="blackAlpha.600">
        <Flex flexDirection="column" h="100%" justifyContent="center">
          <Container maxW="container.sm">
            <Box textAlign="center" color="white"
            as={motion.div}
            variants={variants}
            initial={{ 
                opacity: 0
            }}
            whileInView="animate"
            viewport={{ once: true }}
            >
              <Heading
                fontSize={{ base: '6vw', md: '5vw', lg: '2vw' }}
                as="h2"
                textTransform="uppercase"
                lineHeight="1"
                mb="4"
              >
                The Teton River
              </Heading>
              <Text fontSize="clamp(0.8rem, 1rem, 1.25rem)">
                The Teton River headwaters originate in the Teton Valley, Idaho,
                a north-south trending valley defined by the convergence of
                three mountain ranges (the Teton, Snake River and Big Hole
                mountain ranges). The Teton River flows northwesterly through
                the valley before entering Teton Canyon. From here, the river
                flows westward to the confluence with the Henry’s Fork River
                near Rexburg, Idaho. The Henry’s Fork flows into the Snake
                River, which flows into the Columbia River and then into the
                Pacific Ocean.
              </Text>
              <Text fontSize="clamp(1rem, 1.125rem, 1.25rem)">
                This project is an example of effective collaboration among
                Friends of the Teton River, community stakeholders, conservation
                groups and local, state and federal agency partners.
              </Text>
            </Box>
          </Container>
        </Flex>
      </Box>
    </Box>
  );
}
