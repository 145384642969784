import React from 'react';
import {
  Box,
  Center,
  Heading,
  Flex,
  Link,
} from '@chakra-ui/react';
import TheResultsImg from '../../images/the-results.jpg'
import TreeIcon from '../Icons/TreeIcon';
import TractorIcon from '../Icons/TractorIcon';
import { motion } from 'framer-motion';

export default function TheResults() {
    return (
        <Box as="section" my="24" bg="white" scrollSnapAlign={{base: "none", md: "start"}}>
            <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column', sm: 'row'}} justifyContent="space-around" alignItems="center">
                <Box w={{base: "100%", md:"40%"}} maxH="100vh"
                as={motion.div}
                viewport={{once: true}}
                initial={{ 
                    opacity: 0
                }}
                    whileInView={{
                        opacity: 1,
                        transition: {
                            delay: .5,
                            duration: 1.25,
                            type: 'easeInOut',
                            stiffness: 100
                        }
                    }}
                >
                    <img src={TheResultsImg} alt="Buxton River Park" style={{maxHeight: '100vh'}} />    
                </Box>
                <Box w={{base: "100%", md: "60%"}} px={{base: "1%", sm: "1%", md: "5%"}} py="12">
                    <Box 
                    as={motion.div}
                    viewport={{once: true}}
                    initial={{ 
                        opacity: 0
                    }}
                        whileInView={{
                            opacity: 1,
                            transition: {
                                duration: 1.25,
                                type: 'easeInOut',
                                stiffness: 100
                            }
                        }}>
                        <Center>
                            <Box h="0.5rem" bg="brand.500" w="4rem" mb="4"/>
                        </Center>
                        <Heading as="h2" fontSize={{base: "12vw", md: "3vw"}} color="gray.600" mb="24" textAlign="center">
                            How To<br />Engage
                        </Heading>
                    </Box >
                    <Flex flexWrap='wrap' px={{base: 8, md: 0}}>
                        <Box w={{base: "100%", lg: "50%"}} mb="12"
                        as={motion.div}
                        viewport={{once: true}}
                        initial={{ 
                            opacity: 0
                        }}
                            whileInView={{
                                opacity: 1,
                                transition: {
                                    delay: 1,
                                    duration: 1.25,
                                    type: 'easeInOut',
                                    stiffness: 100
                                }
                            }}
                        >
                            <Center>
                                <TreeIcon fill="gray.600" w="24" h="24" mb="4" />
                            </Center>
                            <Heading as="h4" textAlign="center" fontSize="1rem" color="gray.600" mb="8">
                                Private Landowners &amp; Recreation Users
                            </Heading>
                            <Box textAlign="center" px="8" mb="2">
                                <Link href="mailto:daniel@henrysfork.org" target="_blank" rel='noopner noreferrer' color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}}>
                                    Contact Friends of the Teton River
                                </Link>
                            </Box>
                            <Box textAlign="center" px="8" mb="2">
                                <Link href="https://tetonlandtrust.org/connect/staff/" target="_blank" rel='noopner noreferrer' color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}}>
                                    Contact Teton Regional Land Trust
                                </Link>
                            </Box>
                            <Box textAlign="center" px="8" mb="2">
                                <Link href="https://www.tetonwater.org/wp-content/uploads/2019/10/RiparianConservationGuide_ForWeb-LowRes.pdf" target="_blank" rel='noopner noreferrer' color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}}>
                                FTR’s Stream Corridor Conservation Guide
                                </Link>
                            </Box>
                        </Box>
                        <Box w={{base: "100%", lg: "50%"}} mb="12"
                        as={motion.div}
                        viewport={{once: true}}
                        initial={{ 
                            opacity: 0
                        }}
                            whileInView={{
                                opacity: 1,
                                transition: {
                                    delay: 1.5,
                                    duration: 1.25,
                                    type: 'easeInOut',
                                    stiffness: 100
                                }
                            }}
                        >
                            <Center>
                            <TractorIcon fill="gray.600" w="24" h="24"  mb="4" />
                            </Center>
                            <Heading as="h4" textAlign="center" fontSize="1rem" color="gray.600" mb="8">
                                Agricultural Producers
                            </Heading>
                            <Box textAlign="center" px="8" mb="2">
                                <Link href="https://www.nrcs.usda.gov/getting-assistance/conservation-technical-assistance" target="_blank" rel='noopner noreferrer' color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}}>
                                    Contact Daniel Wilcox at Friends of the Teton River
                                </Link>
                            </Box>
                        </Box>
                        {/* <Box w={{base: "100%", lg: "33.33%"}} mb="12"
                            as={motion.div}
                            viewport={{once: true}}
                            initial={{ 
                                opacity: 0
                            }}
                                whileInView={{
                                    opacity: 1,
                                    transition: {
                                        delay: 2,
                                        duration: 1.25,
                                        type: 'easeInOut',
                                        stiffness: 100
                                    }
                                }}
                        >
                            <Center>
                            <BuildingIcon fill="gray.600" w="24" h="24" mb="4" />
                            </Center>
                            <Heading as="h4" textAlign="center" fontSize="1rem" color="gray.600" mb="8">
                                Public Agencies &amp; Business Owners
                            </Heading>
                            <Box textAlign="center" px="8" mb="2">
                                <Link href="https://www.fema.gov/flood-maps/know-your-risk" target="_blank" rel='noopner noreferrer' color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}}>
                                    Contact your local FEMA officer
                                </Link>
                            </Box>
                        </Box> */}
                    </Flex>
                </Box>
            </Flex>    
        </Box>
    )
}