import {
  Icon
} from '@chakra-ui/react'

const ArrowIcon = (props) => (
<Icon viewBox="0 0 29.476 25.125" {...props}>
<path fill="currentColor" d="M29.044,11.527L17.96,.445c-.274-.275-.651-.432-1.039-.432L9.599,0c-.597-.001-1.135,.357-1.364,.908-.228,.551-.103,1.186,.319,1.608l5.868,5.867-12.948,.002c-.388,0-.769,.157-1.042,.431-.275,.274-.432,.655-.432,1.043v5.364c0,.388,.157,.768,.432,1.042s.654,.432,1.042,.432H14.481l-5.914,5.913c-.421,.422-.547,1.056-.319,1.607,.229,.551,.765,.908,1.361,.908h7.31c.393,0,.764-.152,1.042-.431l11.083-11.083c.278-.278,.432-.648,.432-1.042s-.154-.764-.432-1.042"/>
</Icon>
)

export default ArrowIcon