import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";

import {
  Box,
  Flex,
  Heading
} from '@chakra-ui/react'
import TheResultsImg from '../../images/Results-Photo-1.jpg'
import TheResultsImg2 from '../../images/Results-Photo-2.jpg'
import TheResultsImg3 from '../../images/Results-Photo-3.jpg'
import TheResultsImg4 from '../../images/Results-Photo-4.jpg'
import TheResultsImg5 from '../../images/Results-Photo-5.jpg'
import TheResultsImg6 from '../../images/Results-Photo-6.jpg'

gsap.registerPlugin(ScrollTrigger);

export default function GsapComponent() {
  const component = useRef(null);
  const pinSlider = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

    const result1 = document.getElementById("result1");
    gsap.set(result1, { yPercent: 0, opacity: 1 });

    const resultImg1 = document.getElementById("resultImg1");
    gsap.set(resultImg1, { opacity: 1 });

    const dotOne = document.getElementsByClassName("dotOne");
    gsap.set(dotOne, { backgroundColor: '#d7c60d' });

    const result2= document.getElementById("result2");
    gsap.set(result2, { yPercent: 0, opacity: 0 });

    const resultImg2 = document.getElementById("resultImg2");
    gsap.set(resultImg2, { opacity: 0 });

    const dotTwo = document.getElementsByClassName("dotTwo");
    gsap.set(dotTwo, { backgroundColor: 'lightgray' });

    const result3 = document.getElementById("result3");
    gsap.set(result3, { yPercent: 0, opacity: 0 });

    const resultImg3 = document.getElementById("resultImg3");
    gsap.set(resultImg3, { opacity: 0 });

    const dotThree = document.getElementsByClassName("dotThree");
    gsap.set(dotThree, { backgroundColor: 'lightgray' });

    const result4 = document.getElementById("result4");
    gsap.set(result4, { yPercent: 0, opacity: 0 });

    const resultImg4 = document.getElementById("resultImg4");
    gsap.set(resultImg4, { opacity: 0 });

    const dotFour = document.getElementsByClassName("dotFour");
    gsap.set(dotFour, { backgroundColor: 'lightgray' });

    const result5 = document.getElementById("result5");
    gsap.set(result5, { yPercent: 0, opacity: 0 });

    const resultImg5 = document.getElementById("resultImg5");
    gsap.set(resultImg5, { opacity: 0 });

    const dotFive = document.getElementsByClassName("dotFive");
    gsap.set(dotFive, { backgroundColor: 'lightgray' });

    const result6 = document.getElementById("result6");
    gsap.set(result6, { yPercent: 0, opacity: 0 });

    const resultImg6 = document.getElementById("resultImg6");
    gsap.set(resultImg6, { opacity: 0 });

    const dotSix = document.getElementsByClassName("dotSix");
    gsap.set(dotSix, { backgroundColor: 'lightgray' });

      gsap.timeline({
        scrollTrigger: {
          trigger: component.current,
          scrub: true,
            snap: {
              snapTo:0.1,
              duration: .5,
              delay: .5,
              ease: "slow.inOut",
            },
          pin: true,
          start: "top top",
          end: "+=3000, bottom",
          markers: false,
        }
      })
        .to(resultImg1, { opacity: 1, duration: 0.5 })
        .to(result1, { yPercent: -50, opacity: 1, duration: .5 })
        .to(result1, { yPercent: -100, opacity: 0, duration: 1.5 })
        .to(dotOne, { backgroundColor: 'lightgray', duration: 0 })
        .to(resultImg1, { opacity: 0, duration: 1.5 })        
        
        .to(result2, { yPercent: -50, opacity: 1, duration: .5 }, '<50%')
        .to(resultImg2, { opacity: 1, duration: 1 }, '<50%')
        .to(dotTwo, { backgroundColor: '#d7c60d', duration: 0 })
        .to(result2, { yPercent: -100, opacity: 0, duration: 1.5 })
        .to(resultImg2, { opacity: 0, duration: .5 })
        .to(dotTwo, { backgroundColor: 'lightgray', duration: 0 })

        .to(result3, { yPercent: -50, opacity: 1, duration: .5 }, '<50%')
        .to(resultImg3, { opacity: 1, duration: 1 }, '<50%')
        .to(dotThree, { backgroundColor: '#d7c60d', duration: 0 })
        .to(result3, { yPercent: -100, opacity: 0, duration: 1.5 })
        .to(resultImg3, { opacity: 0, duration: .5 })
        .to(dotThree, { backgroundColor: 'lightgray', duration: 0 })

        .to(result4, { yPercent: -50, opacity: 1, duration: .5 }, '<50%')
        .to(resultImg4, { opacity: 1, duration: 1 }, '<50%')
        .to(dotFour, { backgroundColor: '#d7c60d', duration: 0 })
        .to(result4, { yPercent: -100, opacity: 0, duration: 1.5 })
        .to(resultImg4, { opacity: 0, duration: .5 })
        .to(dotFour, { backgroundColor: 'lightgray', duration: 0 })
        
        .to(result5, { yPercent: -50, opacity: 1, duration: .5 }, '<50%')
        .to(resultImg5, { opacity: 1, duration: 1 }, '<50%')
        .to(dotFive, { backgroundColor: '#d7c60d', duration: 0 })
        .to(result5, { yPercent: -100, opacity: 0, duration: 1.5 })
        .to(resultImg5, { opacity: 0, duration: .5 })
        .to(dotFive, { backgroundColor: 'lightgray', duration: 0 })

        .to(result6, { yPercent: -50, opacity: 1, duration: .5 }, '<50%')
        .to(resultImg6, { opacity: 1, duration: 1 }, '<50%')
        .to(dotSix, { backgroundColor: '#d7c60d', duration: 0 })
        .to(result6, { yPercent: -50, opacity: 1, duration: 1.5 })
        .to(resultImg6, { opacity: 1, duration: .5 });


    }, component);
    return () => ctx.revert();
  });

  return (
    <Box ref={component} as="section">
      <Box className="pinBox" ref={pinSlider}>
        <Box className="pinSlider" h="100vh">
        <Flex w="100%" flexWrap="nowrap" flexDirection={{base: 'column', lg: 'row'}} justifyContent={{base: "space-between", lg: "space-betwen"}} alignItems={{base: "center", lg: "center"}} h="100%">
        <Box p="4" display={{ base: 'none', lg: 'block'}}>
            <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotOne" />
            <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotTwo" />
            <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotThree" />
            <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotFour" />
            <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotFive" />
            <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotSix" />
        </Box>
        <Flex w={{base: "100%", lg: "50%"}} flexDir="column" justifyContent="center" px="12" mt={{base: 12, lg: -36}}>
        <Box h="100%">
            <Flex mb={{base: "2", lg: "16"}}>
            {/* <Box p="4" display={{ base: 'block', lg: 'none'}} h="100%" display="none">
              <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotOne" />
              <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotTwo" />
              <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotThree" />
              <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotFour" />
              <Box w="10px" h="10px" borderRadius="50%" my="2"  className="dotFive" />
          </Box> */}
              <Box bg="brand.500" w="0.375rem" />
              <Box pl="2">
                  <Heading as="h2" variant="h2" color="gray.600" mb="0" display={{ base: 'none', lg: 'inline'}}>
                  The Results
                  </Heading>
                  <Heading as="h2" variant="h2" color="gray.600" mb="0" display={{ base: 'inline', lg: 'none'}} fontSize="2rem">
                  The Results
                  </Heading>
              </Box>
          </Flex>
        </Box>
        <Box position="relative">
              <Box className="text" id="result1" fontSize="1.25rem" pr={{base: 0, lg: 12}}>
              1,720 linear feet of stabilized and re-vegetated stream banks at the Buxton River Park will decrease erosion and improve water quality in the Teton River.
              </Box>
              <Box className="text" id="result2" fontSize="1.25rem" pr={{base: 0, lg: 12}}>
              Increased riparian vegetation will create stream shading, promote cooler stream temperatures, stabilize banks, provide desirable fish and wildlife habitat, slow stream velocities and promote the natural capture of sediment in stream banks with increased "vegetative roughness."
              </Box>
              <Box className="text" id="result3" fontSize="1.25rem" pr={{base: 0, lg: 12}}>
              Restored stream banks at Buxton River Park will improve sediment conveyance, reducing sediment loads at this site by an estimated 448 tons/year, which will promote cleaner spawning gravels and improve native trout habitat.
              </Box>
              <Box className="text" id="result4" fontSize="1.25rem" pr={{base: 0, lg: 12}}>
              Managed grazing adjacent to stream bank restoration is possible with off-stream watering sites, hardened river access/crossings and removable fencing.  These strategies allow for livestock to be a part of working lands restoration that improves and protects water quality.
              </Box>
              <Box className="text" id="result5" fontSize="1.25rem" pr={{base: 0, lg: 12}}>
              Recreation opportunities from wildlife viewing to floating the river are enhanced by restoration. Fewer sediment bars, deeper swimming holes, and healthy riparian forests create an improved experience for those people getting out and enjoying our river ecosystem.
              </Box>
              <Box className="text" id="result6" fontSize="1.25rem" pr={{base: 0, lg: 12}}>
              Cooler stream temperatures and improved riparian habitat are beneficial for native Yellowstone cutthroat trout as well as other coldwater fish species. A more robust YCT population means better angling opportunities for private anglers and for local guides.
              </Box>
         </Box>
        </Flex>
            <Flex w={{base: "100%", lg: "50%"}} h={{base: "auto", lg: "100%"}} flexDir="column" justifyContent="center">
              <Box className="content" bg="white" id="resultImg1" bottom={{base: 0, lg: 'unset'}}>
                  <img src={TheResultsImg} alt="Buxton River Park" />    
              </Box>
              <Box className="content" bg="white" id="resultImg2" bottom={{base: 0, lg: 'unset'}}>
                  <img src={TheResultsImg2} alt="Buxton River Park" />    
              </Box>
              <Box className="content" bg="white" id="resultImg3" bottom={{base: 0, lg: 'unset'}}>
                  <img src={TheResultsImg3} alt="Buxton River Park" />    
              </Box>
              <Box className="content" bg="white" id="resultImg4" bottom={{base: 0, lg: 'unset'}}>
                  <img src={TheResultsImg4} alt="Buxton River Park" />    
              </Box>
              <Box className="content" bg="white" id="resultImg5" bottom={{base: 0, lg: 'unset'}}>
                  <img src={TheResultsImg5} alt="Buxton River Park" />    
              </Box>
              <Box className="content" bg="white" id="resultImg6" bottom={{base: 0, lg: 'unset'}}>
                  <img src={TheResultsImg6} alt="Buxton River Park" />    
              </Box>
            </Flex>
        </Flex>
          </Box>
      </Box>
    </Box>
  )
}