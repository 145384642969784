import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Text,
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import FishIcon from '../Icons/FishIcon';

export const DecreasedFisheriesMobile = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>      
        <Modal isOpen={isOpen} onClose={onClose} isCentered id="staff-modal" bg="red" size="sm">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Loss of Fish Habitat</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text xw fontWeight="700" textAlign="center" fontSize="0.8rem">
                In this section of the upper Teton River, loss of riparian forest contributes to increased sedimentation that can smother spawning gravels and loss of vegetation that creates shade and quality in-stream habitat for fish.
            </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
    
        <Box position="absolute" top="50%" left="60%" w="20%" display={{ base: 'block', xl: 'none' }} cursor="pointer" onClick={onOpen}>
           <Flex alignItems="flex-start">
           <Box mr="-10px">
                <PulseDot />
            </Box>
                <Flex flexDirection="column" justifyContent="center" alignItems="center"  >
                    <FishIcon h="30%" w="30%" fill="white" />
                    <Heading as="h4" color="white" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1" textAlign="center">
                    Loss of<br />Fish Habitat
                    </Heading>
                </Flex>
           </Flex>
        </Box>
        <Modal />
        </>
    )
}


