import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Text,
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import FishIcon from '../Icons/FishIcon';

export const YellowstoneCutthroatMobile = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>      
        <Modal isOpen={isOpen} onClose={onClose} isCentered id="staff-modal" bg="red" size="sm">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Yellowstone Cutthroat</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text xw fontWeight="700" textAlign="center" fontSize="0.8rem">
            Friends of the Teton River (FTR) has identified the upper Teton River as a priority area for restoration actions because it is considered one of the remaining strongholds for Yellowstone cutthroat trout (YCT) populations which have been extirpated from much of their historic range. 
            </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
    
        <Box position="absolute" top="82%" left="60%" w="15%" display={{ base: 'block', xl: 'none' }} cursor="pointer" onClick={onOpen}>
           <Flex alignItems="flex-start">
           <Box ml="-10px">
                <PulseDot />
            </Box>
               <Flex flexDirection="column" justifyContent="center" alignItems="center"  >
                    <FishIcon h="30%" w="30%" fill="brand.500" />
                    <Heading as="h4" color="brand.500" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1" textAlign="center">
                    Yellowstone<br />Cutthroat
                    </Heading>
                </Flex>
                
           </Flex>
        </Box>
        <Modal />
        </>
    )
}


