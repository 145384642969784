import React from 'react';
import {
    Box,
    Flex,
    Text,
    Heading,
    Link
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import DegradedIcon from '../Icons/DegradedIcon'
import ArrowIcon from '../Icons/RightArrow';
import { motion } from 'framer-motion';

const variants = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1.25,
            delay: 1,
        }
      }
    }

export const DegradedHabitat = () => (
    <Box position="absolute" top="62%" left="54%" w="20%" h="auto"  display={{ base: 'none', xl: 'block' }}>
       <Flex alignItems="flex-start" 
    //     as={motion.div}
    //     variants={variants}
    //     whileInView="animate"
    //     initial={{ 
    //         opacity: 0,
    //     }}
    //     viewport=
    // {{once: true}}
    >
       
            <Flex flexDirection="column" justifyContent="center" alignItems="center" mt="-16%">
                <DegradedIcon h="15%" w="10%" fill="white" />
                <Heading as="h4" color="white" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1" textAlign="center">
                Impaired<br />Water Quality
                </Heading>
                <Box w="95%" >
                    <Text color="white" fontWeight="700" textAlign="center" fontSize="0.8rem">
                    Excessive sediment from eroding stream banks, over-widened channels and and increasing stream temperatures are factors that contributed to the Teton River’s listing as impaired for degraded habitat, sediment and temperature throughout its length. <Link href="https://mywaterway.epa.gov/waterbody-report/IDEQ/ID17040204SK020_04/2022" target=
                    "_blank" rel="noopener noreferrer" color="brand.500" fontWeight="900" _hover={{textDecoration: 'none', color: 'gray.500'}}>Read the EPA Report <ArrowIcon pb="1" fill="brand.500"/></Link>
                    </Text>
                </Box>
            </Flex>
            <Box >
            <PulseDot />
        </Box>
       </Flex>
    </Box>
)