import React from 'react';
import {
    Box,
    Flex,
    Text,
    Heading
} from '@chakra-ui/react';
import { PulseDot } from '../PulseDot/PulseDot';
import TramplingIcon from '../Icons/TramplingIcon'
import { motion } from 'framer-motion';

const variants = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1.25,
            delay: 2.5,
        }
      }
    }

export const Trampling = () => (
    <Box position="absolute" top="20%" left="38%" w="20%" h="auto" display={{ base: 'none', xl: 'block' }}>
       <Flex alignItems="flex-start">
           <Box mr="1%"> <PulseDot /></Box>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" mt="-29%" >
                <TramplingIcon h="15%" w="25%" fill="white" />
                <Heading as="h4" color="white" fontSize="clamp(1rem, 2vw, 1.5rem)" my="1">
                    Land Use Impacts
                </Heading>
                <Box>
                    <Text color="white" fontWeight="700" textAlign="center" fontSize="0.8rem">
                        Past land use practices such as unmanaged grazing, removal of vegetation, and excessive sedimentation.
                    </Text>
                </Box>
            </Flex>
       </Flex >
    </Box>
)