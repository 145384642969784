import React from 'react';
import {
    Box,
    Flex,
    Container,
    Heading,
    Center,
    Text
} from '@chakra-ui/react';
import Circle1 from '../../images/circles/circle_1.jpg';
import Circle2 from '../../images/circles/circle_2.jpg';
import Circle3 from '../../images/circles/circle_3.jpg';
import Circle4 from '../../images/circles/circle_4.jpg';
import Circle5 from '../../images/circles/circle_5.jpg';
import CardOne from '../../images/circles/CardOne.svg';
import CardTwo from '../../images/circles/CardTwo.svg';
import CardThree from '../../images/circles/CardThree.svg';
import CardFour from '../../images/circles/CardFour.svg';
import CardFive from '../../images/circles/CardFive.svg';
import RiparianBackground from '../../images/riparian-background.jpg';

import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const headline = {
    animate: {
        opacity: 1,
        transition: {
            duration: 1.25
        }
      }
    }

const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 2,
        staggerChildren: 0.5
      }
    },
  }

  const item = {
    hidden: { opacity: 0, scale: 0.5 },
    show: { opacity: 1, scale: 1 }
  }

const StyledImg = styled(Box)`
    background-image: url(${props => props.src});
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    border-radius: 50%;
    background-color: #0d7a74;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

export default function RiparianForests(props) {
    return (
       <Box as="section" h={{base: 'auto', lg:'80vh'}}>
        <Box>
            <Box display={{base: 'none', xl: 'block'}}>
                <img src={RiparianBackground} alt="riparian background" w="100%" style={{objectFit: 'cover', objectPosition: 'top', width: '100%' }}/></Box >
            <Container maxW="container.xl"
            textAlign="center"
            scrollSnapAlign={{base: "none", md: "start"}}
            //minHeight="100vh"
            w="100%"
            marginTop={{base: "0", md: "0%", xl: '-25%', '2xl': '-20%'}}
            >
                <Flex flexDirection="column" justifyContent="center" h="100%">
                    <Container maxW="conatiner.lg" textAlign="center" my={{base: 2, lg: 12, xl: 8, '2xl': 16}} as={motion.div} initial={{opacity: 0}} variants={headline} whileInView="animate" viewport={{once: true}}>
                        <Center>
                            <Box h="0.5rem" bg="brand.500" w="4rem" mb="4"/>
                        </Center>
                        <Heading as="h2" textAlign="center" variant="h2" color={{base: 'gray.600', xl: 'white'}} px={{base: 2, lg: 24}}>
                            Why Are Riparian Forests Important?
                        </Heading>
                    </Container>
                    <Flex w="100%" justifyContent="center" flexWrap="wrap"
                        as={motion.div} 
                        variants={container} 
                        initial="hidden"
                        whileInView="show"
                        viewport={{once: true}}
                         >
                        <Box p="4" w={{base: '50%', md: '33.33%', lg: '20%'}} borderRadius="50%"
                         as={motion.div}
                         variants={item}
                        >
                            <StyledImg src={Circle1} alt="circle 1">
                            <Box position="absolute" top="0" w="100%" p="4">
                                <img src={CardOne} alt="card one" />
                            </Box>
                            </StyledImg>
                        </Box>
                        {/* <Box p="4" w={{base: '50%', md: '33.33%', lg: '20%'}}
                        as={motion.div}
                        variants={item}
                         >
                            <StyledImg src={Circle2} alt="circle 2">
                            <Box position="absolute" top="0" w="100%" p="4">
                                <img src={CardTwo} alt="card two" />
                            </Box>
                            </StyledImg>
                        </Box> */}
                        <Box p="4" w={{base: '50%', md: '33.33%', lg: '20%'}}
                        as={motion.div}
                        variants={item}
                        >
                            <StyledImg src={Circle3} alt="circle 3">
                            <Box position="absolute" top="0" w="100%" p="4">
                                <img src={CardThree} alt="card three" />
                            </Box>
                            </StyledImg>
                        </Box>
                        <Box p="4" w={{base: '50%', md: '33.33%', lg: '20%'}}
                        as={motion.div}
                        variants={item}
                        >
                            <StyledImg src={Circle4} alt="circle 4">
                            <Box position="absolute" top="0" w="100%" p="4">
                                <img src={CardFour} alt="card four" />
                            </Box>
                            </StyledImg>
                        </Box>
                        <Box p="4" w={{base: '50%', md: '33.33%', lg: '20%'}}
                        as={motion.div}
                        variants={item}
                        >
                            <StyledImg src={Circle5} alt="circle 5">
                            <Box position="absolute" top="0" w="100%" p="4">
                                <img src={CardFive} alt="card five" />
                            </Box>
                            </StyledImg>
                        </Box>
                    </Flex>
                </Flex>
           </Container>
        </Box>
       </Box>
    )
}