import {
  Icon
} from '@chakra-ui/react'

const TramplingIcon = (props) => (
  
<Icon viewBox="0 0 210.1504 115.89034" {...props}>
<path class="cls-1" d="M102.20685,25.00002c-.60001,8.5-1.3,16.89999-2,27,5.10001-5.60001,9-9.89999,12.8-14.2-3.7,12.10001-14,21.5-11.89999,35.7,1.89999,12.60001,4,25,10.10001,36.5-3.3-20.3,.39999-40,4.7-59.59999,2.2,20.8,4.39999,41.60001,6.7,63.60001,6-7.10001,10.5-28.89999,12.3-47.3,1.70001-17-5.39999-31.2-11-46.3,2.89999,4,5.8,8,8.60001,12,.5-.10001,1.10001-.3,1.60001-.39999-1.8-10.39999-3.60001-20.7-5.5-31.8,6.20001,4.6,9.79999,10.6,11.10001,17.2,2.29999,11.3,3.20001,22.8,4.79999,34.2,1.20001,8.2,2.70001,16.3,5,24.5,6-20.5,13.60001-39.7,27.29999-56.09999-14.79999,29.8-18.79999,62-21.60001,94.39999-9.5,2.89999-10.60001,2.10001-11.29999-7.2-.39999-5.60001-1.10001-11.2-3-16.8-1.5,6.7-3.10001,13.3-4.29999,20.10001-.70001,4.10001-2.70001,5.39999-6.8,5.2-7.60001-.3-15.3,.10001-22.89999-.3-1.89999-.10001-4.7-2-5.39999-3.7-2.60001-6.10001-4.39999-12.5-7.5-18.8v22.10001h-7.7c-2.3-13.8-.7-27.10001,3.3-40.5,1.3-4.39999,2.10001-9.10001,2.39999-13.8,.59996-12.60002,3.09996-24.70001,10.19997-35.70001Z"/>
      <path class="cls-1" d="M20.00685,92.40002v22.10001c-7.3,2.10001-7.7,1.7-8.3-5.89999-.89999-12.7,.8-25,5.10001-37,.89999-2.5,.7-5.39999,1.3-8,2.2-11.3,4.5-22.60001,6.89999-33.8,.39999-1.7,1.7-3.3,3.39999-4.60001-.60001,8.39999-1.2,16.8-2,26.8,5.10001-5.7,9.10001-10.10001,13.10001-14.5-3.89999,11.89999-14,21.3-12.3,35.09999,1.60001,12.7,3.7,25.10001,9.8,36.60001-2.5-20,.5-39.5,4.89999-60.2,2.39999,22.2,4.60001,43.2,6.8,64.2,.8-.10001,1.60001-.10001,2.39999-.2,10.39999-31.3,17.3-62.59999-2.10001-93.90001,1.8,2.3,3.60001,4.5,5.2,6.89999,1.7,2.3,3.2,4.8,4.8,7.2,.5-.2,.89999-.3,1.39999-.5-1.89999-10.60001-3.7-21.2-5.60001-31.8,.39999-.3,.8-.6,1.2-.9,2.89999,4.6,6.39999,8.9,8.60001,13.9,5.5,12.5,6,25.8,6.2,39.2,.10001,5,1.3,9.89999,1.89999,14.89999h1.8c.8-5.5,1.60001-11.10001,2.60001-17.39999,2.39999,21.89999,4.7,42.90001,7,64.3h-13.10001c-.89999-8.3-1.8-16.7-2.7-25.10001-.39999,0-.8-.10001-1.10001-.10001-1.39999,6.60001-3.2,13.10001-4.2,19.8-.7,4.5-2.60001,6.3-7.3,6.10001-7.2-.39999-14.5-.3-21.7,0-3.8,.2-5.7-1.2-6.8-4.7-1.89999-6.2-4-12.2-6.10001-18.3-.39996-.19998-.69997-.19998-1.09996-.19998Z"/>
      <path class="cls-1" d="M194.00684,115.10001h-11c-.70001-6.39999-1-12.60001-2.10001-18.60001-3.70001-20.7-6.60001-41.5-4-62.5,1.20001-9.8,3.10001-19.4,9.10001-27.5,1.60001-2.1,3.39999-4.1,5.70001-5.9-6.70001,29.6-8.89999,59.1-2.29999,88.9,5.60001-21.3,4.60001-42.89999,3.20001-65,1.39999,3.3,3.10001,6.39999,4.29999,9.8,7.5,21.39999,6,42.8,.20001,64.2-1.50003,5.20001-2.10004,10.60001-3.10004,16.60001Z"/>
      <path class="cls-1" d="M174.70685,114.90002c-9.10001,1.60001-10.29999,.89999-11.70001-8.8-2.70001-18.89999,.60001-37.39999,4.60001-57,2.5,22.70001,4.80002,44.20001,7.10001,65.8Z"/>
      <path class="cls-1" d="M3.60685,52.40002c2.3,20.7,4.49999,41.40001,6.8,62.5-4.60001,2-6.9-.3-8-5.2C-1.49316,93.20002,.10684,76.70002,1.90684,60.10001c.3-2.7,.8-5.3,1.2-8,.2,.20001,.3,.20001,.50001,.3Z"/>
      <path class="cls-1" d="M199.80685,115.00002c2.29999-21.2,4.60001-42,6.79999-62.8,3,19.3,5.70001,38.5,1,57.89999-1.39999,5.60001-1.60001,5.5-7.79999,4.90001Z"/>
</Icon>
)

export default TramplingIcon